export const messages = {
  en: {
    Home: "Home",
    Agenda: "Agenda",
    "Action Plan": "Action Plan",
    "Training Plan": "Training Plan",
    Communication: "Communication",
    Challenges: "Challenges",
    Profile: "Profile",
    Organization: "Organization",
    organization: "Organization",
    Organizations: "Organizations",
    Reporting: "Reporting",
    Logout: "Logout",
    "Role Management": "Role Management",
    AddMember: "Add Member",
    UniqueMember: "Unique member",
    MultipleMembers: "Multiple members",
    MultipleLevels: "Multiple levels",
    GetMembersTemplate: "Get members template",
    GetLevelsTemplate: "Get levels template",
    FirstName: "First name",
    LastName: "Last name",
    Email: "Email",
    DirectReport: "Direct report",
    Localisation: "Localisation",
    Phone: "Phone",
    JobTitle: "Job title",
    "Job description": "Job description",
    Level: "Level",
    TeamLeader: "Team leader",
    Director: "Director",
    RenameProject: "Rename project",
    DeleteProject: "Delete project",
    AddNewOrganization: "Add new organization",
    OrganizationName: "Organization name",
    ProjectName: "Project name",
    Cancel: "Cancel",
    Add: "Add",
    RenameLevel: "Rename level",
    AddLevel: "Add level",
    ViewMembers: "View members",
    DeleteLevel: "Delete level",
    Images: "Images",
    Name: "Name",
    Description: "Description",
    Active: "Active",
    Deadline: "Deadline",
    EditCommunication: "Edit communication",
    DeleteCommunication: "Delete communication",
    Month: "Month",
    Week: "Week",
    List: "List",
    Previous: "Previous",
    Today: "Today",
    Next: "Next",
    Back: "Back",
    AddEvent: "Add event",
    Participants: "Participants",
    Title: "Title",
    Subject: "Subject",
    Attachments: "Attachments",
    Date: "Date",
    Time: "Time",
    Ind: "Ind.",
    Grouped: "Grouped",
    IndividualAction: "Individual action",
    MultipleAction: "Multiple actions",
    GetTemplate: "Get template",
    Phase: "Phase",
    Category: "Category",
    Users: "Users",
    "Action Title": "Action title",
    "Due Date": "Due date",
    Points: "Points",
    Cancel: "Cancel",
    Confirm: "Confirm",
    Progress: "Progress",
    Language: "Language",
    "Add Action": "Add Action",
    "Individual Action": "Individual Action",
    "Multiple Actions": "Multiple Actions",
    "Get Template": "Get Template",
    "No actions available": "No actions available",
    Collapse: "Collapse",
    "Collapse all": "Collapse all",
    Expand: "Expand",
    "Expand all": "Expand all",
    "Change order": "Change order",
    Awards: "Points",
    Actions: "Actions",
    "My weekly challenges": "My weekly challenges",
    "My Events": "My Events",
    "No events available": "No events available",
    Status: "Status",
    Team: "Team",
    "My actions plan": "My actions plan",
    "Team actions plan": "Team actions plan",
    "Confirm Deletion": "Confirm Deletion",
    "Are you sure you want to delete this event":
      "Are you sure you want to delete this event",
    "Add Event": "Add Event",
    Project: "Project",
    "Add documents": "Add documents",
    "Edit Event": "Edit event",
    Delete: "Delete",
    "Join the meeting": "Join the meeting",
    Schedules: "Schedules",
    Documents: "Documents",
    "No file uploaded": "No file uploaded",
    "Delete Action": "Delete Action",
    "Are you sure you want to delete the action":
      "Are you sure you want to delete the action",
    "for this user": "for this user",
    Save: "Save",
    "Edit Action": "Edit Action",
    "Add Training": "Add Training",
    "Edit Training": "Edit Training",
    "Delete Training": "Delete Training",
    "Per page": "Per page",
    lines: "lines",
    Training: "Training",
    Roles: "Roles",
    "Are you sure you want to delete this training":
      "Are you sure you want to delete this training",
    "Add Communication": "Add communication",
    "Edit Communication": "Edit communication",
    "Delete Communication": "Delete communication",
    "Communication description": "Communication description",
    "Upload Image": "Upload Image",
    "Active communication": "Active communication",
    "Turn On": "Turn On",
    "Turn Off": "Turn Off",
    "Are you sure you want to delete this communication":
      "Are you sure you want to delete this communication",
    Edit: "Edit",
    "Add Challenge": "Add Challenge",
    "Edit Challenge": "Edit Challenge",
    "Delete Challenge": "Delete Challenge",
    "Are you sure you want to delete this challenge":
      "Are you sure you want to delete this challenge",
    Unique: "Unique",
    "My Profile": "My Profile",
    "My personal information": "My personal information",
    Me: "Me",
    Hair: "Hair",
    "Skin color": "Skin color",
    "Facial expression": "Facial expression",
    "Facial hair": "Facial hair",
    "Top clothes": "Top clothes",
    "Bottom clothes": "Bottom clothes",
    Shoes: "Shoes",
    "Save avatar": "Save avatar",
    "Phone Number": "Phone Number",
    "Add member": "Add member",
    "Unique member": "Unique member",
    "Multiple members": "Multiple members",
    "Multiple levels": "Multiple levels",
    "Get members template": "Get members template",
    "Get levels template": "Get levels template",
    "Edit user": "Edit user",
    "Delete user": "Delete user",
    "Add Roles": "Add Roles",
    "Mandatory fields": "Mandatory fields",
    "Add Role": "Add Role",
    "Select Role": "Select Role",
    "Level Name": "Level Name",
    "Level Description": "Level Description",
    Admin: "Admin",
    "Team leader": "Team leader",
    "Select A Level": "Select A Level",
    "Enter Job Description": "Job Description",
    "Users View": "Users view",
    "Organization View": "Organization View",
    "Add Level": "Add Level",
    "Project Team": "Project Team",
    "Rename Level": "Rename Level",
    "View Members": "View Members",
    "Delete Level": "Delete Level",
    Rename: "Rename",
    "Level members": "Level members",
    Close: "Close",
    Member: "Member",
    "Selected A Member": "Selected A Member",
    "Are you sure you want to delete the level":
      "Are you sure you want to delete the level",
    "This action will delete all the other levels inside of it if they exist":
      "This action will delete all the other levels inside of it if they exist",
    "Rename Project": "Rename Project",
    "Delete Project": "Delete Project",
    "Add new organization": "Add new organization",
    "Are you sure you want to delete the project":
      "Are you sure you want to delete the project",
    "This action will also delete users, and actions connected to this project.":
      "This action will also delete users, and actions connected to this project.",
    "Select phase": "Select phase",
    "Select category": "Select category",
    "Select Level": "Select Level",
    "Save Chart": "Save Chart",
    Export: "Export",
    "Zoom in": "Zoom in",
    "Zoom out": "Zoom out",
    "Reset zoom": "Reset zoom",
    Details: "Details",
    "Create new role": "Create new role",
    "See more": "See more",
    "rights assigned": "rights assigned",
    Collaborator: "Collaborator",
    "Team Leader": "Team Leader",
    "View Organization Page": "View Organization Page",
    "View Actions Page": "View Actions Page",
    "Consult Your Action Plan": "Consult Your Action Plan",
    "Consult Your Teams Action Plans": "Consult Your Teams Action Plans",
    "Consult All Other Collaborators Plans":
      "Consult All Other Collaborators Plans",
    "Add Actions To Your Action Plan": "Add Actions To Your Action Plan",
    "Add Actions To Your Teams Action Plan":
      "Add Actions To Your Teams Action Plan",
    "Add Actions To Any Other Collaborators Action Plan":
      "Add Actions To Any Other Collaborators Action Plan",
    "Modify The Percentage Of Completion Of Actions In Your Action Plan":
      "Modify The Percentage Of Completion Of Actions In Your Action Plan",
    "Modify The Percentage Of Completion Of Actions In A Member Of Your Teams Action Plan":
      "Modify The Percentage Of Completion Of Actions In A Member Of Your Teams Action Plan",
    "Modify The Percentage Of Completion Of Actions In Any Other Collaborators Action Plan":
      "Modify The Percentage Of Completion Of Actions In Any Other Collaborators Action Plan",
    "Modify An Action On Your Action Plan":
      "Modify An Action On Your Action Plan",
    "Modify An Action On A Member Of Your Teams Action Plan":
      "Modify An Action On A Member Of Your Teams Action Plan",
    "Modify An Action On Any Other Collaborators Action Plan":
      "Modify An Action On Any Other Collaborators Action Plan",
    "Remove An Action On Your Action Plan":
      "Remove An Action On Your Action Plan",
    "Remove An Action On A Member Of Your Teams Action Plan":
      "Remove An Action On A Member Of Your Teams Action Plan",
    "Remove An Action On Any Other Collaborators Action Plan":
      "Remove An Action On Any Other Collaborators Action Plan",
    "View Agenda Page": "View Agenda Page",
    "View Communication Page": "View Communication Page",
    "View Training Page": "View Training Page",
    "Add new role": "Add new role",
    "My Trainings": "My Trainings",
    "Create a ticket": "Create a ticket",
    Hello: "Hello",
    "How can we help": "How can we help",
    Create: "Create",
    "Select Organization": "Select Organization",
    "Select Participants": "Select Participants",
    "Event Title": "Event Title",
    "Event Subject": "Event Subject",
    "Select roles": "Select roles",
    "Communication Name": "Communication Name",
    "Create a challenge": "Create Challenge",
    "Rights For": "Rights For",
    "Edit Roles": "Edit Roles",
    "Edit member": "Edit member",
    "Are you sure you want to delete user":
      "Are you sure you want to delete user",
    "from organization": "from organization",
    "This user will be permanently deleted from this organization":
      "This user will be permanently deleted from this organization",
    "All actions linked to this user will be also removed":
      "All actions linked to this user will be also removed",
    "Organization Name": "Organization Name",
    "Project Name": "Project Name",
    "Select or Enter a phase": "Select or Enter a phase",
    "Select or Enter a category": "Select or Enter a category",
    "Select Users": "Select Users",
    "Action description": "Action description",
    BackOfficeRoles: {
      "Chef de projet": "Project Manager",
      "Expert IT": "IT Expert",
      BPO: "BPO",
      KU: "KU",
      Formateur: "Trainer",
      Communication: "Communication",
      "Conduite de changement": "Change management",
    },
    "Chef de projet": "Project Manager",
    "Expert IT": "IT Expert",
    BPO: "BPO",
    KU: "KU",
    Formateur: "Trainer",
    Communication: "Communication",
    "Conduite de changement": "Change management",
    confirm: "Confirm",
    "Meeting link not available": "Meeting link not available",
    "No challenges available": "No challenges available",
    "No news available": "No news available",
    "No job description available": "No job description available",
    createdAt: "Created at",
  },

  fr: {
    Home: "Accueil",
    Agenda: "Calendrier",
    "Action Plan": "Plan d'action",
    "Training Plan": "Plan de formation",
    Communication: "Communication",
    Challenges: "Défis",
    Profile: "Profil",
    Reporting: "Rapports",
    Organization: "Organisation",
    organization: "Organisation",
    Organizations: "Organisations",
    Reporting: "Rapports",
    "Expert IT": "Informatique expert",
    Logout: "Déconnexion",
    "Role Management": "Gestion des rôles",
    AddMember: "Ajouter un membre",
    UniqueMember: "Membre unique",
    MultipleMembers: "Plusieurs membres",
    MultipleLevels: "Plusieurs niveaux",
    GetMembersTemplate: "Obtenir le modèle de membres",
    GetLevelsTemplate: "Obtenir le modèle de niveaux",
    FirstName: "Prénom",
    LastName: "Nom",
    Email: "E-mail",
    DirectReport: "Rapport direct",
    Localisation: "Localisation",
    Phone: "Téléphone",
    JobTitle: "Titre d'emploi",
    "Job description": "Intitulé du poste",
    Level: "Niveau",
    TeamLeader: "Chef d'équipe",
    Director: "Directeur",
    RenameProject: "Renommer le projet",
    DeleteProject: "Supprimer le projet",
    AddNewOrganization: "Ajouter une nouvelle organisation",
    OrganizationName: "Nom de l'organisation",
    ProjectName: "Nom du projet",
    Cancel: "Annuler",
    Add: "Ajouter",
    RenameLevel: "Renommer le niveau",
    AddLevel: "Ajouter un niveau",
    ViewMembers: "Voir les membres",
    DeleteLevel: "Supprimer le niveau",
    Images: "Images",
    Name: "Nom",
    Description: "Description",
    Active: "Actif",
    Deadline: "Date limite",
    "News description": "Description de l'actualité",
    "Edit News": "Editer l'actualité",
    "Edit News": "Editer l'actualité",
    Actions: "Actions",
    EditCommunication: "Modifier la communication",
    DeleteCommunication: "Supprimer la communication",
    Month: "Mois",
    Week: "Semaine",
    List: "Liste",
    Previous: "Précédent",
    Today: "Aujourd'hui",
    Next: "Suivant",
    Back: "Retour",
    AddEvent: "Ajouter un évènement",
    Participants: "Participants",
    Title: "Titre",
    Subject: "Sujet",
    Attachments: "Pièces jointes",
    Date: "Date",
    "Edit Roles": "Modifier les rôles",
    Time: "Heure",
    Ind: "Unique",
    Grouped: "Groupé",
    IndividualAction: "Action individuelle",
    MultipleAction: "Plusieurs actions",
    GetTemplate: "Obtenir le modèle",
    Phase: "Phase",
    Category: "Catégorie",
    Users: "Utilisateurs",
    "Training courses": "Cours de formation",
    Draft: "Brouillon",
    sessions: "séances",
    Session: "Séance",
    "Edit Training": "Modifier la formation",
    Sessions: "Séances",
    Group: "Groupe",
    "Course Name": "Nom du cours",
    "Training Color": "Couleur",
    "Add training session": "Ajouter une séance",
    "Action Title": "Titre de l'action",
    "Due date": "Date d'échéance",
    close: "fermer",
    Close: "Fermer",
    "Add News": "Ajouter une actualité",
    "Are you sure you want to delete this News":
      "Êtes-vous sûr de vouloir supprimer cette actualité",
    "No data": "Pas de données",
    // "Deadline":"délai",
    "Active News": "Actualité active",
    Points: "Points",
    Cancel: "Annuler",
    Confirm: "Confirmer",
    Progress: "Progrès",
    Language: "Langue",
    Events: "Evénements",
    Goal: "Objectif",
    goal: "Objectif",
    "News Name": "Nom de l'actualité",
    "Select type": "Sélectionner le type",
    "Select badge": "Sélectionner un badge",
    "Event Date": "Date de l'événement",
    "Not specified": "Non spécifié",
    "Select All": "Sélectionner tout",
    "Add Action": "Ajouter une action",
    "Current Users": "Utilisateurs actuels",
    "Current Levels": "Niveaux Actuels",
    "Other selections": "Autres Sélections",
    "Add multiple action": "Ajouter plusieurs actions",
    "Individual Action": "Action individuelle",
    "Multiple Actions": "Plusieurs actions",
    "Get Template": "Obtenir le modèle",
    "No actions available": "Aucune action disponible",
    Collapse: "Réduire",
    "Collapse all": "Réduire tout",
    Expand: "Développer",
    "Expand all": "Développer tout",
    "Change order": "Changer l'ordre",
    Awards: "Points",
    Actions: "Actions",
    "My weekly challenges": "Mes défis hebdomadaires",
    "My Events": "Mes évènements",
    "No events available": "Aucun évènement disponible",
    "No training available": "Aucune formation disponible",
    "My action plan": "mon plan d'action",
    News: "Actualité",
    "No Active News": "Aucune nouvelle active",
    "Project Levels": "Niveau des projets",
    "Team' action plan": "Plan d’action de l’équipe",
    "Events and trainings": "Événements et Formations",
    Status: "Statut",
    Team: "Équipe",
    "My actions plan": "Mon plan d'action",
    "Team actions plan": "Plan d'action de l'équipe",
    "Confirm Deletion": "Confirmer la suppression",
    "Are you sure you want to delete this event":
      "Êtes-vous sûr de vouloir supprimer cet évènement",
    "Add Event": "Ajouter un évènement",
    "Add multiple levels": "Ajouter plusieurs niveaux",
    "Are you sure you want to delete the project":
      "Êtes-vous sûr de vouloir supprimer le projet",
    "Add multiple members": "Ajouter plusieurs membres",
    Project: "Projet",
    "Add documents": "Ajouter des documents",
    "Edit Event": "Modifier l'évènement",
    Delete: "Supprimer",
    "Join the meeting": "Rejoindre la réunion",
    Schedules: "Horaires",
    Documents: "Documents",
    "No file uploaded": "Aucun fichier téléchargé",
    "Delete Action": "Supprimer l'action",
    "Are you sure you want to delete the action":
      "Êtes-vous sûr de vouloir supprimer l'action",
    "for this user": "pour cet utilisateur",
    Save: "Sauvegarder",
    "Edit Action": "Modifier l'action",
    "Add Training": "Ajouter une formation",
    "Add training": "Ajouter une formation",
    "Edit Training": "Modifier la formation",
    "Delete Training": "Supprimer la formation",
    "Per page": "Par page",
    lines: "lignes",
    "Rights for": "Droits pour",
    Training: "Formation",
    Month: "Mois",
    Developer: "Développeur",
    developer: "Développeur",
    Levels: "Niveaux",
    "Job Description": "Description du poste",
    "Current Selection": "Sélection actuelle",
    "Current Job description": "Description du poste actuel",
    Roles: "Rôles",
    "Are you sure you want to delete this training":
      "Êtes-vous sûr de vouloir supprimer cette formation",
    "Add Communication": "Ajouter une communication",
    "Edit Communication": "Modifier la communication",
    "Delete Communication": "Supprimer la communication",
    "Communication description": "Description de la communication",
    "Upload Image": "Télécharger une image",
    "Active communication": "Communication active",
    "Turn On": "Activer",
    "Turn Off": "Désactiver",
    "Are you sure you want to delete this communication":
      "Êtes-vous sûr de vouloir supprimer cette communication",
    Edit: "Modifier",
    "Add Challenge": "Ajouter un défi",
    "Edit Challenge": "Modifier le défi",
    "Delete Challenge": "Supprimer le défi",
    "Are you sure you want to delete this challenge":
      "Êtes-vous sûr de vouloir supprimer ce défi",
    Unique: "Unique",
    "My Profile": "Mon profil",
    "My personal information": "Mes informations personnelles",
    Me: "Moi",
    Hair: "Cheveux",
    "Skin color": "Couleur de peau",
    "Facial expression": "Expression du visage",
    "Facial hair": "Barbe",
    "Top clothes": "Haut",
    "Bottom clothes": "Bas",
    Shoes: "Chaussures",
    "Save avatar": "Sauvegarder l'avatar",
    "Phone Number": "Numéro de téléphone",
    "Add member": "Ajouter",
    "Unique member": "Membre unique",
    "Last Name": "Nom",
    "Delete selected": "Effacer les éléments sélectionnés",
    "First Name": "Prenom",
    "Add role": "Ajouter rôle",
    "There are no members for this level":
      "Il n'y a pas de membres pour ce niveau",
    "Are you sure to delete": "Êtes-vous sûr de supprimer",
    "Delete all": "Supprimer tout",
    "Are you sure to delete all users ?":
      "Êtes-vous sûr de supprimer tous les utilisateurs ?",
    "Are you sure to delete selected users ?":
      "Êtes-vous sûr de vouloir supprimer les utilisateurs sélectionnés ?",
    "Role Type": "Type de rôle",
    Localization: "Localisation",
    "Multiple members": "Plusieurs membres",
    "Multiple levels": "Plusieurs niveaux",
    "Get members template": "Obtenir le modèle de membres",
    "Get levels template": "Obtenir le modèle de niveaux",
    "Edit user": "Modifier l'utilisateur",
    "Delete user": "Supprimer l'utilisateur",
    "Add Roles": "Ajouter des rôles",
    "Mandatory fields": "Champs obligatoires",
    "Add Role": "Ajouter un rôle",
    "Select Role": "Sélectionner un rôle",
    "Level Name": "Nom du niveau",
    "Level Description": "Description du niveau",
    Admin: "Admin",
    "Team leader": "Chef d'équipe",
    "Select A Level": "Sélectionner un niveau",
    "Enter Job Description": "Description de l'emploi",
    "Users View": "Vue des utilisateurs",
    "Organization View": "Vue de l'organisation",
    "Add Level": "Ajouter un niveau",
    "Project Team": "Équipe du projet",
    "Rename Level": "Renommer le niveau",
    "View Members": "Voir les membres",
    "Delete Level": "Supprimer le niveau",
    Rename: "Renommer",
    "Level members": "Membres du niveau",
    Close: "Fermer",
    Member: "Membre",
    "Selected A Member": "Sélectionner un membre",
    "Are you sure you want to delete the level":
      "Êtes-vous sûr de vouloir supprimer le niveau",
    "This action will delete all the other levels inside of it if they exist":
      "Cette action supprimera tous les autres niveaux à l'intérieur s'ils existent",
    "Rename Project": "Renommer le projet",
    "Delete Project": "Supprimer le projet",
    "Add new organization": "Ajouter une nouvelle organisation",
    "Are you sure you want to delete the project":
      "Êtes-vous sûr de vouloir supprimer le projet",
    "This action will also delete users, and actions connected to this project.":
      "Cette action supprimera également les utilisateurs et les actions liées à ce projet.",
    "Select Phase": "Sélectionner une phase",
    "Select Category": "Sélectionner une catégorie",
    "Select Level": "Sélectionner un niveau",
    "Save Chart": "Sauvegarder le graphique",
    Export: "Exporter",
    "Zoom in": "Zoomer",
    "Zoom out": "Dézoomer",
    "Reset zoom": "Réinitialiser le zoom",
    Details: "Détails",
    "Create new role": "Créer un nouveau rôle",
    "See more": "Voir plus",
    "rights assigned": "droits attribués",
    Collaborator: "Collaborateur",
    "Team Leader": "Chef d'équipe",
    "View Organization Page": "Voir la page de l'organisation",
    "View Actions Page": "Voir la page des actions",
    "Consult Your Action Plan": "Consulter votre plan d'action",
    "Consult Your Teams Action Plans":
      "Consulter les plans d'action de votre équipe",
    "Consult All Other Collaborators Plans":
      "Consulter les plans de tous les autres collaborateurs",
    "Add Actions To Your Action Plan":
      "Ajouter des actions à votre plan d'action",
    "Add Actions To Your Teams Action Plan":
      "Ajouter des actions au plan d'action de votre équipe",
    "Add Actions To Any Other Collaborators Action Plan":
      "Ajouter des actions à tout autre plan d'action de collaborateurs",
    "Modify The Percentage Of Completion Of Actions In Your Action Plan":
      "Modifier le pourcentage de réalisation des actions dans votre plan d'action",
    "Modify The Percentage Of Completion Of Actions In A Member Of Your Teams Action Plan":
      "Modifier le pourcentage de réalisation des actions dans un membre de votre équipe",
    "Modify The Percentage Of Completion Of Actions In Any Other Collaborators Action Plan":
      "Modifier le pourcentage de réalisation des actions dans tout autre plan d'action de collaborateurs",
    "Modify An Action On Your Action Plan":
      "Modifier une action dans votre plan d'action",
    "Modify An Action On A Member Of Your Teams Action Plan":
      "Modifier une action dans un membre de votre équipe",
    "Modify An Action On Any Other Collaborators Action Plan":
      "Modifier une action dans tout autre plan d'action de collaborateurs",
    "Remove An Action On Your Action Plan":
      "Supprimer une action dans votre plan d'action",
    "Remove An Action On A Member Of Your Teams Action Plan":
      "Supprimer une action dans un membre de votre équipe",
    "Remove An Action On Any Other Collaborators Action Plan":
      "Supprimer une action dans tout autre plan d'action de collaborateurs",
    "View Agenda Page": "Voir la page du calendrier",
    "View Communication Page": "Voir la page de la communication",
    "View Training Page": "Voir la page de la formation",
    "Add new role": "Ajouter un nouveau rôle",
    "My Trainings": "Mes formations",
    "Create a ticket": "Créer un ticket",
    Hello: "Bonjour",
    "How can we help": "Comment pouvons-nous vous aider",
    Create: "Créer",
    "Select Organization": "Sélectionner une organisation",
    "Select Participants": "Sélectionner les participants",
    "Event Title": "Titre de l'évènement",
    "Event Subject": "Sujet de l'évènement",
    "Organization levels": "Niveaux d'organisation",
    "Organization members": "Membres de l'organisation",
    "Select roles": "Sélectionner des rôles",
    "Communication Name": "Nom de la communication",
    "Create a challenge": "Créer un défi",
    "Rights For": "Droits pour",
    "Edit Roles": "Modifier les rôles",
    "Edit member": "Modifier le membre",
    "Are you sure you want to delete user":
      "Êtes-vous sûr de vouloir supprimer l'utilisateur",
    "from organization": "de l'organisation",
    "This user will be permanently deleted from this organization":
      "Cet utilisateur sera définitivement supprimé de cette organisation",
    "All actions linked to this user will be also removed":
      "Toutes les actions liées à cet utilisateur seront également supprimées",
    "Organization Name": "Nom de l'organisation",
    "Project Name": "Nom du projet",
    "Select or Enter a phase": "Sélectionner ou saisir une phase",
    "Select or Enter a category": "Sélectionner ou saisir une catégorie",
    "Select Users": "Sélectionner des utilisateurs",
    "Action description": "Description de l'action",
    BackOfficeRoles: {
      "Chef de projet": "Chef de projet",
      "Expert IT": "informatique expert",
      BPO: "BPO",
      KU: "KU",
      Formateur: "Formateur",
      Communication: "Communication",
      "Conduite de changement": "Conduite de changement",
    },
    "Chef de projet": "Chef de projet",
    "Expert IT": "informatique expert",
    BPO: "BPO",
    KU: "KU",
    Formateur: "Formateur",
    Communication: "Communication",
    "Conduite de changement": "Conduite de changement",
    confirm: "Confirmer",
    "Meeting link not available": "Lien de réunion non disponible",
    "No challenges available": "Aucun défi disponible",
    "No news available": "Aucune actualité disponible",
    "No job description available": "Aucune description de poste disponible",
    "Project Manager": "Chef de projet",
    createdAt: "Date de création",
  },
  de: {
    Home: "Startseite",
    Agenda: "Agenda",
    "Action Plan": "Aktionsplan",
    "Training Plan": "Trainingsplan",
    Communication: "Kommunikation",
    Challenges: "Herausforderungen",
    Profile: "Profil",
    Reporting: "Berichterstattung",
    Organization: "Organisation",
    organization: "Organisation",
    Organizations: "Organisationen",
    Reporting: "Berichterstattung",
    "Expert IT": "IT-Experte",
    Logout: "Ausloggen",
    "Role Management": "Rollenverwaltung",
    AddMember: "Mitglied hinzufügen",
    UniqueMember: "Einzigartiges Mitglied",
    MultipleMembers: "Mehrere Mitglieder",
    MultipleLevels: "Mehrere Ebenen",
    GetMembersTemplate: "Mitglieder-Template abrufen",
    GetLevelsTemplate: "Ebenen-Template abrufen",
    FirstName: "Vorname",
    LastName: "Nachname",
    Email: "Email",
    DirectReport: "Direkter Bericht",
    Localisation: "Lokalisierung",
    Phone: "Telefon",
    JobTitle: "Berufsbezeichnung",
    "Job description": "Jobbeschreibung",
    Level: "Niveau",
    TeamLeader: "Teamleiter",
    Director: "Direktor",
    RenameProject: "Projekt umbenennen",
    DeleteProject: "Projekt löschen",
    AddNewOrganization: "Neue Organisation hinzufügen",
    OrganizationName: "Organisationsname",
    ProjectName: "Projektname",
    Cancel: "Stornieren",
    Add: "Hinzufügen",
    RenameLevel: "Ebene umbenennen",
    AddLevel: "Ebene hinzufügen",
    ViewMembers: "Mitglieder anzeigen",
    DeleteLevel: "Ebene löschen",
    Images: "Bilder",
    Name: "Name",
    Description: "Beschreibung",
    Active: "Aktiv",
    Deadline: "Frist",
    "News description": "Nachrichtenbeschreibung",
    "Edit News": "Nachrichten bearbeiten",
    "Edit News": "Nachrichten bearbeiten",
    Actions: "Aktionen",
    EditCommunication: "Kommunikation bearbeiten",
    DeleteCommunication: "Kommunikation löschen",
    Month: "Monat",
    Week: "Woche",
    List: "Liste",
    Previous: "Bisherige",
    Today: "Heute",
    Next: "Nächster",
    Back: "Zurück",
    AddEvent: "Ereignis hinzufügen",
    Participants: "Teilnehmer",
    Title: "Titel",
    Subject: "Gegenstand",
    Attachments: "Anhänge",
    Date: "Datum",
    "Edit Roles": "Rollen bearbeiten",
    Time: "Zeit",
    Ind: "Einzigartig",
    Grouped: "Gruppiert",
    IndividualAction: "Individuelle Aktion",
    MultipleAction: "Mehrere Aktionen",
    GetTemplate: "Vorlage abrufen",
    Phase: "Phase",
    Category: "Kategorie",
    Users: "Benutzer",
    "Action Title": "Aktionsbezeichnung",
    "Due date": "Fälligkeitsdatum",
    close: "schließen",
    Close: "Schließen",
    "Add News": "Nachrichten hinzufügen",
    "Are you sure you want to delete this News":
      "Möchten Sie diese Nachricht wirklich löschen",
    "No data": "Keine Daten",
    // "Deadline":"délai",
    "Active News": "Aktive Nachrichten",
    Points: "Punkte",
    Cancel: "Stornieren",
    Confirm: "Bestätigen",
    Progress: "Fortschritt",
    Language: "Sprache",
    Events: "Veranstaltungen",
    Goal: "Ziel",
    goal: "Ziel",
    "News Name": "Nachrichtenname",
    "Select type": "Typ auswählen",
    "Select badge": "Abzeichen auswählen",
    "Event Date": "Ereignisdatum",
    "Not specified": "Nicht spezifiziert",
    "Select All": "Alles auswählen",
    "Add Action": "Aktion hinzufügen",
    "Current Users": "Aktuelle Benutzer",
    "Current Levels": "Aktuelle Ebenen",
    "Other selections": "Andere Auswahlmöglichkeiten",
    "Add multiple action": "Mehrere Aktionen hinzufügen",
    "Individual Action": "Individuelle Aktion",
    "Multiple Actions": "Mehrere Aktionen",
    "Get Template": "Vorlage abrufen",
    "No actions available": "Keine Aktionen verfügbar",
    Collapse: "Zusammenbruch",
    "Collapse all": "Alle reduzieren",
    Expand: "Erweitern",
    "Expand all": "Alle erweitern",
    "Change order": "Reihenfolge ändern",
    Awards: "Punkte",
    Actions: "Aktionen",
    "My weekly challenges": "Meine wöchentlichen Herausforderungen",
    "My Events": "Meine Veranstaltungen",
    "No events available": "Keine Veranstaltungen verfügbar",
    "No training available": "Keine Schulung verfügbar",
    "My action plan": "mein Aktionsplan",
    News: "Nachrichten",
    "No Active News": "Keine aktiven Nachrichten",
    "Project Levels": "Projektebenen",
    "Team' action plan": "Team-Aktionsplan",
    "Events and trainings": "Veranstaltungen und Schulungen",
    Status: "Status",
    Team: "Mannschaft",
    "My actions plan": "Mein Aktionsplan",
    "Team actions plan": "Team-Aktionsplan",
    "Confirm Deletion": "Löschung bestätigen",
    "Are you sure you want to delete this event":
      "Möchten Sie dieses Ereignis wirklich löschen",
    "Add Event": "Ereignis hinzufügen",
    "Add multiple levels": "Mehrere Ebenen hinzufügen",
    "Are you sure you want to delete the project":
      "Möchten Sie das Projekt wirklich löschen",
    "Add multiple members": "Mehrere Mitglieder hinzufügen",
    Project: "Projekt",
    "Add documents": "Dokumente hinzufügen",
    "Edit Event": "Ereignis bearbeiten",
    Delete: "Löschen",
    "Join the meeting": "Treffen beitreten",
    Schedules: "Zeitpläne",
    Documents: "Dokumente",
    "No file uploaded": "Keine Datei hochgeladen",
    "Delete Action": "Aktion löschen",
    "Are you sure you want to delete the action":
      "Möchten Sie die Aktion wirklich löschen",
    "for this user": "für diesen Benutzer",
    Save: "Speichern",
    "Edit Action": "Aktion bearbeiten",
    "Add Training": "Schulung hinzufügen",
    "Edit Training": "Schulung bearbeiten",
    "Delete Training": "Schulung löschen",
    "Per page": "Pro Seite",
    lines: "Linien",
    "Rights for": "Rechte für",
    Training: "Ausbildung",
    Month: "Monat",
    Developer: "Entwickler",
    developer: "Entwickler",
    Levels: "Ebenen",
    "Job Description": "Jobbeschreibung",
    "Current Selection": "Aktuelle Auswahl",
    "Current Job description": "Aktuelle Jobbeschreibung",
    Roles: "Rollen",
    "Are you sure you want to delete this training":
      "Möchten Sie diese Schulung wirklich löschen",
    "Add Communication": "Kommunikation hinzufügen",
    "Edit Communication": "Kommunikation bearbeiten",
    "Delete Communication": "Kommunikation löschen",
    "Communication description": "Kommunikationsbeschreibung",
    "Upload Image": "Bild hochladen",
    "Active communication": "Aktive Kommunikation",
    "Turn On": "Einschalten",
    "Turn Off": "Ausschalten",
    "Are you sure you want to delete this communication":
      "Möchten Sie diese Kommunikation wirklich löschen",
    Edit: "Bearbeiten",
    "Add Challenge": "Herausforderung hinzufügen",
    "Edit Challenge": "Herausforderung bearbeiten",
    "Delete Challenge": "Herausforderung löschen",
    "Are you sure you want to delete this challenge":
      "Möchten Sie diese Herausforderung wirklich löschen",
    Unique: "Einzigartig",
    "My Profile": "Mein Profil",
    "My personal information": "Meine persönlichen Informationen",
    Me: "Ich",
    Hair: "Haare",
    "Skin color": "Hautfarbe",
    "Facial expression": "Gesichtsausdruck",
    "Facial hair": "Gesichtsbehaarung",
    "Top clothes": "Oberteile",
    "Bottom clothes": "Unterteile",
    Shoes: "Schuhe",
    "Save avatar": "Avatar speichern",
    "Phone Number": "Telefonnummer",
    "Add member": "Hinzufügen",
    "Unique member": "Einzigartiges Mitglied",
    "Last Name": "Nachname",
    "Delete selected": "Ausgewählte löschen",
    "First Name": "Vorname",
    "Add role": "Rolle hinzufügen",
    "There are no members for this level":
      "Es gibt keine Mitglieder für dieses Level",
    "Are you sure to delete": "Möchten Sie wirklich löschen",
    "Delete all": "Alles löschen",
    "Are you sure to delete all users ?":
      "Möchten Sie wirklich alle Benutzer löschen?",
    "Are you sure to delete selected users ?":
      "Möchten Sie die ausgewählten Benutzer wirklich löschen?",
    "Role Type": "Rollenart",
    Localization: "Lokalisierung",
    "Multiple members": "Mehrere Mitglieder",
    "Multiple levels": "Mehrere Ebenen",
    "Get members template": "Mitglieder-Template abrufen",
    "Get levels template": "Ebenen-Template abrufen",
    "Edit user": "Benutzer bearbeiten",
    "Delete user": "Benutzer löschen",
    "Add Roles": "Rollen hinzufügen",
    "Mandatory fields": "Pflichtfelder",
    "Add Role": "Rolle hinzufügen",
    "Select Role": "Rolle auswählen",
    "Level Name": "Ebenenname",
    "Level Description": "Ebenenbeschreibung",
    Admin: "Admin",
    "Team leader": "Teamleiter",
    "Select A Level": "Ein Level auswählen",
    "Enter Job Description": "Jobbeschreibung eingeben",
    "Users View": "Benutzeransicht",
    "Organization View": "Organisationsansicht",
    "Add Level": "Ebene hinzufügen",
    "Project Team": "Projektteam",
    "Rename Level": "Ebene umbenennen",
    "View Members": "Mitglieder anzeigen",
    "Delete Level": "Ebene löschen",
    Rename: "Umbenennen",
    "Level members": "Ebenenmitglieder",
    Close: "Schließen",
    Member: "Mitglied",
    "Selected A Member": "Ein Mitglied ausgewählt",
    "Are you sure you want to delete the level":
      "Möchten Sie das Level wirklich löschen",
    "This action will delete all the other levels inside of it if they exist":
      "Diese Aktion löscht alle anderen Ebenen darin, wenn sie existieren",
    "Rename Project": "Projekt umbenennen",
    "Delete Project": "Projekt löschen",
    "Add new organization": "Neue Organisation hinzufügen",
    "Are you sure you want to delete the project":
      "Möchten Sie das Projekt wirklich löschen",
    "This action will also delete users, and actions connected to this project.":
      "Diese Aktion löscht auch Benutzer und mit diesem Projekt verbundene Aktionen.",
    "Select Phase": "Phase auswählen",
    "Select Category": "Kategorie auswählen",
    "Select Level": "Ebene auswählen",
    "Save Chart": "Diagramm speichern",
    Export: "Exportieren",
    "Zoom in": "Hineinzoomen",
    "Zoom out": "Herauszoomen",
    "Reset zoom": "Zoom zurücksetzen",
    Details: "Einzelheiten",
    "Create new role": "Neue Rolle erstellen",
    "See more": "Mehr sehen",
    "rights assigned": "Rechte zugewiesen",
    Collaborator: "Mitarbeiter",
    "Team Leader": "Teamleiter",
    "View Organization Page": "Organisationsseite anzeigen",
    "View Actions Page": "Aktionen anzeigen",
    "Consult Your Action Plan": "Konsultieren Sie Ihren Aktionsplan",
    "Consult Your Teams Action Plans":
      "Konsultieren Sie die Aktionspläne Ihres Teams",
    "Consult All Other Collaborators Plans":
      "Konsultieren Sie alle anderen Pläne der Mitarbeiter",
    "Add Actions To Your Action Plan":
      "Aktionen zu Ihrem Aktionsplan hinzufügen",
    "Add Actions To Your Teams Action Plan":
      "Aktionen zum Aktionsplan Ihres Teams hinzufügen",
    "Add Actions To Any Other Collaborators Action Plan":
      "Aktionen zu jedem anderen Aktionsplan der Mitarbeiter hinzufügen",
    "Modify The Percentage Of Completion Of Actions In Your Action Plan":
      "Ändern Sie den Prozentsatz der Fertigstellung von Aktionen in Ihrem Aktionsplan",
    "Modify The Percentage Of Completion Of Actions In A Member Of Your Teams Action Plan":
      "Ändern Sie den Prozentsatz der Fertigstellung von Aktionen in einem Mitglied Ihres Teams",
    "Modify The Percentage Of Completion Of Actions In Any Other Collaborators Action Plan":
      "Ändern Sie den Prozentsatz der Fertigstellung von Aktionen in jedem anderen Aktionsplan der Mitarbeiter",
    "Modify An Action On Your Action Plan":
      "Eine Aktion in Ihrem Aktionsplan ändern",
    "Modify An Action On A Member Of Your Teams Action Plan":
      "Eine Aktion in einem Mitglied Ihres Teams ändern",
    "Modify An Action On Any Other Collaborators Action Plan":
      "Eine Aktion in jedem anderen Aktionsplan der Mitarbeiter ändern",
    "Remove An Action On Your Action Plan":
      "Eine Aktion in Ihrem Aktionsplan entfernen",
    "Remove An Action On A Member Of Your Teams Action Plan":
      "Eine Aktion in einem Mitglied Ihres Teams entfernen",
    "Remove An Action On Any Other Collaborators Action Plan":
      "Eine Aktion in jedem anderen Aktionsplan der Mitarbeiter entfernen",
    "View Agenda Page": "Agenda-Seite anzeigen",
    "View Communication Page": "Kommunikationsseite anzeigen",
    "View Training Page": "Trainingsseite anzeigen",
    "Add new role": "Neue Rolle hinzufügen",
    "My Trainings": "Meine Schulungen",
    "Create a ticket": "Ein Ticket erstellen",
    Hello: "Hallo",
    "How can we help": "Wie können wir helfen",
    Create: "Erstellen",
    "Select Organization": "Organisation auswählen",
    "Select Participants": "Teilnehmer auswählen",
    "Event Title": "Ereignistitel",
    "Event Subject": "Ereignisgegenstand",
    "Organization levels": "Organisationsebenen",
    "Organization members": "Organisationsmitglieder",
    "Select roles": "Rollen auswählen",
    "Communication Name": "Kommunikationsname",
    "Create a challenge": "Eine Herausforderung erstellen",
    "Rights For": "Rechte für",
    "Edit Roles": "Rollen bearbeiten",
    "Edit member": "Mitglied bearbeiten",
    "Are you sure you want to delete user":
      "Möchten Sie den Benutzer wirklich löschen",
    "from organization": "von der Organisation",
    "This user will be permanently deleted from this organization":
      "Dieser Benutzer wird dauerhaft aus dieser Organisation gelöscht",
    "All actions linked to this user will be also removed":
      "Alle mit diesem Benutzer verknüpften Aktionen werden ebenfalls entfernt",
    "Organization Name": "Organisationsname",
    "Project Name": "Projektname",
    "Select or Enter a phase": "Phase auswählen oder eingeben",
    "Select or Enter a category": "Kategorie auswählen oder eingeben",
    "Select Users": "Benutzer auswählen",
    "Action description": "Aktionsbeschreibung",
    BackOfficeRoles: {
      "Chef de projet": "Projektleiter",
      "Expert IT": "IT-Experte",
      BPO: "BPO",
      KU: "KU",
      Formateur: "Trainer",
      Communication: "Kommunikation",
      "Conduite de changement": "Veränderungsmanagement",
    },
    "Chef de projet": "Projektleiter",
    "Expert IT": "IT-Experte",
    BPO: "BPO",
    KU: "KU",
    Formateur: "Trainer",
    Communication: "Kommunikation",
    "Conduite de changement": "Veränderungsmanagement",
    "Meeting link not available": "Meeting-Link nicht verfügbar",
    "No challenges available": "Keine Herausforderungen verfügbar",
    "No news available": "Keine Nachrichten verfügbar",
    "No job description available": "Keine Jobbeschreibung verfügbar",
    createdAt: "Erstellungsdatum",
  },
};
