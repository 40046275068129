<template>
  <div class="support-modal">
    <CModal
      class="support-modal-body"
      id="Ticket"
      alignment="center"
      :show.sync="visible"
    >
      <template v-slot:header>
        <div
          class="d-flex"
          style="
            padding-left: 1rem;
            justify-content: space-between;
            align-items: center;
            width: 100%;
          "
        >
          <h3 style="margin-top: 5px">{{ $t("Create a ticket") }}</h3>
          <CButton @click="closeModal">
            <i class="fa fa-times"></i>
          </CButton>
        </div>
      </template>
      <section>
        <div class="ModalWithSelects">
          <div>
            <label>{{ $t("Title") }}</label>
            <CInput class="input" v-model="message.title" />
          </div>
          <div>
            <label>{{ $t("Type") }}</label>
            <SelectUI
              class="type-select"
              v-model="message.type"
              :options="typeFields"
              :searchable="false"
              :clearable="false"
            />
          </div>
<!--          <div>-->
<!--            <label>{{ $t("Page") }}</label>-->
<!--            <SelectUI-->
<!--              class="type-select"-->
<!--              v-model="message.page"-->
<!--              :options="pageFields"-->
<!--              :searchable="false"-->
<!--              :clearable="false"-->
<!--            />-->
<!--          </div>-->
          <div>
            <label>{{ $t("Description") }}</label>
            <CTextarea
              class="description-textarea"
              v-model="message.description"
            />
          </div>
        </div>
      </section>
      <template v-slot:footer>
        <div
          style="
            padding: 0 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <div class="footer">
            <button
              class="btn btn-primary cancelButton"
              @click="closeModal"
              button-type="cancel"
            >
              {{ $t("Cancel") }}
            </button>
            <CButton
              color="primary"
              class="send-btn"
              @click="sendMessage"
              :disabled="isSendBtnDisabldes"
              >{{ $t("Create") }}</CButton
            >
          </div>
        </div>
      </template>
      <template slot="footer"> </template>
    </CModal>
  </div>
</template>

<script>
import SelectUI from "../ui/selects/SelectUI.vue";
import { SupportService } from "@/services/support";
import { ActiveUser } from "@/services/user";
// import "vue3-toastify/dist/index.css";
import Message from "vue-m-message";
export default {
  name: "SupportModal",
  props: ["isVisible", "closePopup", "currentPage", "form"],
  components: {
    SelectUI,
  },
  data() {
    return {
      user: ActiveUser.user.email,
      visible: false,
      isSendBtnDisabldes: true,
      typeFields: [
        { label: "Bug", id: "Bug" },
        { label: "Error", id: "Error" },
        { label: "Enhancements", id: "Enhancements" },
      ],
      pageDefaultFields: [
        {
          label: "Optional",
          id: "Optional",
        },
        {
          label: "Book team",
          id: "Book team",
        },
      ],
      pageFields: [
        { label: "Login", id: "login" },
        { label: "Home", id: "home" },
        { label: "Agenda", id: "agenda" },
        { label: "Action Plan", id: "actionPlan" },
        { label: "Training Plan", id: "trainingPlan" },
        { label: "News", id: "communication" },
        { label: "Challenges", id: "challenges" },
        { label: "Profile", id: "profile" },
        { label: "Organization", id: "organization" },
      ],
      message: {
        title: "",
        type: "Bug",
        page: "Optional",
        description: "",
      },
    };
  },
  watch: {
    isVisible(val) {
      this.visible = val;
    },
    visible(val) {
      if (!val) {
        this.closeModal();
      }
    },
    // currentPage(page) {
    //   this.message.page = this.capitalizeFirstLetter(page);
    // },
    message: {
      async handler(val) {
        let isEmpty = false;

        for (const key in val) {
          if (val[key] === "") {
            isEmpty = true;
            break;
          }
        }

        this.isSendBtnDisabldes = isEmpty;
      },
      deep: true,
    },
  },
  methods: {
   setCurrenntPage() {
    this.currentPage =  this.$route.hash.slice(1) ||this.$route.name;
   },
    async sendMessage() {
      this.isSendBtnDisabldes = true;
      this.sendMessageText = "Send";
      let message;
      if (this.form) {
        const { data } = await SupportService.sendImage(this.form);

        message = {
          author: this.user.user.email,
          title: this.message.title,
          type: this.message.type,
          description: `Page: ${this.$route.name}, Description: ${this.message.description}`,
          attachments: [
            {
              url: data.url,
              markdown: data.markdown,
            },
          ],
        };
      } else {
        message = {
          title: this.message.title,
          type: this.message.type,
          description: `Page: ${this.$route.name
          }, Description: ${this.message.description}`,
        };
      }
      await SupportService.sendMessage(message);
      this.isSendBtnDisabldes = true;
      this.closeModal();
      Message.info(this.$t("Thank you for your feedback!"));
    },
    closeModal() {
      this.message = {
        title: "",
        type: "Bug",
        page: "Optional",
        description: "",
      };
      this.$emit("closePopup");
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
  mounted() {
    this.user = ActiveUser;
  },
};
</script>
<style scoped lang="scss">
.ModalWithSelects {
  display: flex;
  flex-direction: column;
  gap: 10px;

  margin-bottom: 20px;
}

.support-modal::v-deep {
  .modal-dialog.modal-sm {
    min-width: 50% !important;
    padding-left: 25%;
  }
}

.modal-body {
  min-height: 50vh;
}

.support-modal-body::v-deep .modal-dialog {
  min-width: 50vw;
}

.support-modal-body::v-deep .modal-footer {
  padding: 0;
  margin-bottom: 5px;
}

.input::v-deep input,
.description-textarea::v-deep textarea {
  border: 1px solid #eaecf0;
  background-color: #f5f6f8;
  border-radius: 10px;

  @media (max-width: 1439px) {
    border-radius: 7px;
  }
}

.input::v-deep {
  input {
    padding-left: 10px;
    height: 30px;

    @media (max-width: 1439px) {
      height: 35px;
    }
  }
}

.description-textarea::v-deep {
  textarea {
    height: 100px !important;
    max-height: unset !important;
    resize: none;

    &:focus {
      color: #2d2d2d;
    }
  }
}

.type-select::v-deep {
  .vue-treeselect__control {
    padding-left: 10px;
  }
}

section {
  padding: 0 20px;
}

.footer {
  margin-top: 12px;
  margin-bottom: 12px;

  .send-btn {
    margin-left: auto;
  }
}

.cancelButton {
  background-color: #0071ce;
  color: #ffffff;
  margin-right: 10px;

  &:hover {
    background-color: #ffffff;
    color: #0071ce;
  }
}
</style>
