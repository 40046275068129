<template>
  <div>
    <CModal :show.sync="show" alignment="center">
      <template v-slot:header>
        <h3>{{ $t("Add Level") }}</h3>
        <CButton @click="close">
          <i class="fa fa-times"></i>
        </CButton>
      </template>

      <div class="modal-body">
        <div>
          <label for="level">{{ $t("Level Name") }}:</label>
          <CInput id="level" v-model="levelName" />
        </div>
      </div>
      <template v-slot:footer>
        <CButton @click="close" class="link">{{ $t("Cancel") }}</CButton>
        <CButton
          color="primary"
          class="modal-confirm"
          @click="addLevel"
          :disabled="!levelName.trim()"
        >
          {{ $t("Save") }}
        </CButton>
      </template>
    </CModal>
  </div>
</template>
<script>
import { OrganizationsService } from "@/services/organizations";
import vSelect from "vue-select";
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import { mapState } from "vuex";
import "vue-select/dist/vue-select.css";
// import { toast } from "vue3-toastify";
// import "vue3-toastify/dist/index.css";
import { showMessage } from "../../../../utils/helpers";
import { ElLoading } from "element-plus";
export default {
  data() {
    return {
      levelName: "",
    };
  },
  components: {
    vSelect,
    Treeselect,
  },
  props: {
    show: Boolean,
    parentId: String,
    allLevels: Set,
    projectLevel: Boolean,
    addRootProjectLevel: Boolean,
  },
  computed: {
    ...mapState({
      selectedEvent: (state) => state.selectedEvent,
      selectedTraining: (state) => state.selectedTraining,
      userData: (state) => state.userData,
      currentOrganization: (state) => state.selectedOrg,
      currentProject: (state) => state.selectedProject,
      includedOrgs: (state) => state.includedOrgs,
      allOrgs: (state) => state.allOrgs,
      orgObj: (state) => state.orgObj,
    }),
    itExists() {
      return this.allLevels.has(this.levelName);
    },
  },
  watch: {
    show: function (val) {
      if (val) {
      }
    },
  },
  methods: {
    close() {
      this.levelName = "";
      this.$emit("close");
      this.$parent.showAddLevelModal = false;
    },
    async addLevel() {
      this.levelName = this.levelName.trim();

      if (this.itExists) {
        showMessage(
          "error",
          "Level name already exists, please choose a different name"
        );
        return;
      }
      if (!this.projectLevel && !this.addRootProjectLevel) {
        const { data, res } = await OrganizationsService.add_level({
          org_name:
            this.currentOrganization.name ||
            this.currentOrganization.organization,
          project_name: this.currentProject.name,
          level_name: this.levelName,
          parent_id: this.parentId ? this.parentId : "",
          org_id: this.orgObj.id,
        });
        if (data.success) {
          showMessage("success", "Level Added Successfully");
          this.close();
          this.refreshData();
        } else {
          showMessage("error", "Could not add level");
          alert(data.detail);
        }
      } else {
        const { data, res } = await OrganizationsService.add_project_level({
          org_name:
            this.currentOrganization.name ||
            this.currentOrganization.organization,
          project_name: this.currentProject.name,
          level_name: this.levelName,
          parent_id: this.parentId ? this.parentId : "",
          org_id: this.orgObj.id,
        });
        if (data.success) {
          this.close();
          this.refreshData();
        } else {
          alert(data.message);
        }
      }
    },
    refreshData() {
      this.$parent.refreshData();
    },
  },
};
</script>
<style scoped>
:root {
  --vs-search-input-placeholder-color: #bdbdbd;
  --vs-font-size: 14px;
}
.modal-confirm {
  border-radius: 0px 0px 0px 25px;
  overflow: unset !important;
}
</style>
