import Message from "vue-m-message";
import { useUserStore } from "@/stores/UserStore";
import { useGlobalStore } from "@/stores/store";

import { UsersService } from "@/services/users";
import store from "@/store";

export const getNumericalValue = (value, round = 2) => {
  if (value === "0.0 %") {
    return "0 %";
  }
  if (typeof value === typeof 1) {
    if (value === 0) {
      return "-";
    }
    value = value.toFixed(round).replace(/\B(?=(\d{3})+(?!\d))/g, "\xa0");
  }
  if (value === "0") {
    return "-";
  }
  return value;
};

export const showMessage = (type, message) => {
  Message[type](message, {
    position: "bottom-left",
    zIndex: 2050,
  });
};

export const updateMyData = async () => {
  const userStore = useUserStore();
  const globalStore = useGlobalStore();

  const userData = await UsersService.getActiveUserInfo();
  if (userData.data) {
    store.commit("setUserData", userData.data);
    store.commit("setOrgObj", userData.data.current_organization);
    store.commit("setJobsPoints", userData.data.level_points_v2);
    const firstEntry = Object.entries(userData.data.current_job_description)[0];
    store.commit("setJob", firstEntry);

    let currentLevel = userData.data.current_level;
    if (currentLevel === "") {
      let currentRole = Object.keys(userData.data.current_job_description)[0];
      currentLevel = userData.data.level_points_v2.map((level) => {
        if (level.job === currentRole) {
          return level.level;
        }
      })[0];
    }
    userStore.setCurrentLevel(currentLevel);
    userStore.setUserPoints(userData.data.total_points); // user points in action plan
    userStore.setUserTrainingPoints(userData.data.training_total_points); // user points in training plan
    userStore.setUserTrainingProgress(userData.data.training_points); // user progress in training plan
    userStore.setTeamTrainingPoints(userData.data.training_team_points); // team points in training plan
    const teamPointsData = userData.data.level_points_v2;
    const neededPointsData = teamPointsData.filter((point) => {
      return point.job === firstEntry[0] && point.level === currentLevel;
    })[0];

    userStore.setTeamPoints(neededPointsData);
    userStore.setUsersProgress(userData.data.total_progress);
    const teamProgressData = userData.data.level_progress_v2;
    const neededProgressData = teamProgressData.filter((point) => {
      return (
        point.job === firstEntry[0] &&
        point.level === userData.data.current_level
      );
    })[0];
    userStore.setAllRolePoints(userData.data.all_roles_points);
    userStore.setUsersTeamProgress(neededProgressData);
    globalStore.setUpdatedProgress(false);
  }
};
