<template>
  <div>
    <CModal :show.sync="show" alignment="center" size="lg" :draggable="false">
      <template v-slot:header>
        <div
          style="
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <h3>{{ $t("Level Members") }}</h3>
          <div style="display: flex; align-items: center">
            <CButton
              v-if="doesHaveRight('addUsersToLevels')"
              @click="openAddMemberModal()"
              color="primary"
              style="border-radius: 0 0 0 25px"
              >{{ $t("Add member") }}</CButton
            >
            <CButton @click="close">
              <i class="fa fa-times"></i>
            </CButton>
          </div>
        </div>
      </template>

      <div class="modal-body">
        <div v-if="node.members.length > 0">
          <table class="table">
            <tr style="background: #d2cfcf">
              <th>{{ $t("FirstName") }}</th>
              <th>{{ $t("LastName") }}</th>
              <th>{{ $t("Email") }}</th>
              <th v-if="!projectLevel">{{ $t("Director") }}</th>
              <th v-if="!projectLevel">{{ $t("Team leader") }}</th>
              <th>{{ $t("Actions") }}</th>
            </tr>
            <tr v-for="(member, index) in node.members" :key="index">
              <td>{{ member.firstname }}</td>
              <td>{{ member.lastname }}</td>
              <td>{{ member.email }}</td>
              <td v-if="!projectLevel">
                <input
                  type="checkbox"
                  v-model="member.admin"
                  @change="statusChanged(member)"
                />
              </td>
              <td v-if="!projectLevel">
                <input
                  type="checkbox"
                  v-model="member.responsible"
                  @change="statusChanged(member)"
                />
              </td>
              <td>
                <div>
                  <img
                    @click="deleteMember(member)"
                    v-c-tooltip="'Delete Member'"
                    class="img"
                    :src="require('@/assets/Images/cross.png')"
                    alt="delete"
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div
          v-else
          style="display: flex; justify-content: center; align-items: center"
        >
          <h3>{{ $t("There are no members for this level") }}</h3>
        </div>
      </div>
      <template v-slot:footer>
        <CButton @click="close" class="link">{{ $t("Close") }}</CButton>
        <!-- <CButton color="primary" class="modal-confirm" @click="addLevel">
          Save
        </CButton> -->
      </template>
    </CModal>
    <AddMemberModal
      :show="showAddMemberModal"
      @close="showAddMemberModal = false"
      :node="node"
      :orgMembers="orgMembers"
      :projectLevel="projectLevel"
      :projectLevelOptions="projectLevelOptions"
      :projectData="projectData"
    />
  </div>
</template>
<script>
import { OrganizationsService } from "@/services/organizations";
import { UsersService } from "@/services/users";
import vSelect from "vue-select";
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import "vue-select/dist/vue-select.css";
import AddMemberModal from "../organizationPage/AddMemberModal.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      showAddMemberModal: false,
    };
  },
  components: {
    vSelect,
    Treeselect,
    AddMemberModal,
  },
  computed: {
    ...mapState({
      selectedEvent: (state) => state.selectedEvent,
      selectedTraining: (state) => state.selectedTraining,
      userData: (state) => state.userData,
      currentOrganization: (state) => state.selectedOrg,
      currentProject: (state) => state.selectedProject,
      includedOrgs: (state) => state.includedOrgs,
      allOrgs: (state) => state.allOrgs,
    }),
  },
  props: {
    show: Boolean,
    parentId: String,
    node: Object,
    orgMembers: Array,
    orgViewData: Object,
    projectLevel: Boolean,
    projectLevelOptions: Array,
    projectData: Array,
  },

  methods: {
    doesHaveRight(right) {
      return this.$parent.doesHaveRight(right);
    },
    close() {
      this.$emit("close");
    },
    openAddMemberModal() {
      this.showAddMemberModal = true;
    },
    addMemberFromTable(memberRoles, selectedMember) {
      this.$parent.addMemberFromTable(memberRoles, selectedMember);
    },
    async statusChanged(member) {
      // Find the current member in orgMembers
      let neededMember = this.orgMembers.find((m) => m.email === member.email);

      // Find the index of the role to change in orgMembers
      let roleToChangeIndex = neededMember.roles.findIndex(
        (r) => r.level_name === this.node.level_name
      );

      neededMember.project_name = this.currentProject.name;
      neededMember.roles[roleToChangeIndex].admin = member.admin;
      neededMember.roles[roleToChangeIndex].responsible = member.responsible;

      // Perform the edit member operation for the needed member
      const { data, res } = await OrganizationsService.edit_member({
        organization:
          this.currentOrganization.name ||
          this.currentOrganization.organization,
        email: member.email,
        user_data: neededMember,
      });
      if (data.success) {
        const userData = await UsersService.getActiveUserInfo();
        this.$store.commit("setJobsPoints", userData.data.level_points);
      }
    },
    async deleteMember(member) {
      let neededMember = this.orgMembers.find((m) => m.email === member.email);
      console.log(this.orgMembers);
      let roleToChangeIndex = neededMember.roles.findIndex(
        (r) => r.level_name === this.node.level_name
      );

      // Check if the role exists
      if (roleToChangeIndex !== -1) {
        // Remove the role at the found index
        neededMember.roles.splice(roleToChangeIndex, 1);
        neededMember.project_name = this.currentProject.name;

        // Find the index of the member in the orgMembers array
        const memberToDeleteIndex = this.orgMembers.findIndex(
          (m) => m.email === member.email
        );
        // Remove the member from the orgMembers array
        if (memberToDeleteIndex !== -1) {
          this.orgMembers.splice(memberToDeleteIndex, 1);
        }

        // Remove the member from the node.members array
        const memberToDeleteIndexInNode = this.node.members.findIndex(
          (m) => m.email === member.email
        );
        if (memberToDeleteIndexInNode !== -1) {
          this.node.members.splice(memberToDeleteIndexInNode, 1);
        }

        const { data, res } = await OrganizationsService.edit_member({
          organization:
            this.currentOrganization.name ||
            this.currentOrganization.organization,
          project: this.currentProject.name,
          email: member.email,
          user_data: neededMember,
        });
      } else {
      }
    },
  },
};
</script>
<style scoped>
.img {
  width: 12px;
  height: 12px;
  margin-left: 15px;
  cursor: pointer;
}
</style>
