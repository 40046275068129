<template>
    <div class="help-menu">
        <div class="icon" @click="toggleIcon">
            <svg-icon type="mdi" :path="helpIcon"></svg-icon>
            <transition name="fade">
                <div class="overlay" v-show="showOverlay">
                    <div class="menu">
                        <div class="menu-title">
                            <h2>Adopt</h2>
                        </div>
                        <div class="menu-header">
                            <h4>{{ $t('Hello') }} {{ userEmail }}!</h4>
                            <h4>{{ $t('How can we help') }}?</h4>
                        </div>
                        <!-- <nav>
                            <ul>
                                <li @click="openUsersGuide">{{ $t('Access user guide') }}</li>
                            </ul>
                        </nav> -->

                        <nav class="mt-1">
                            <ul>
                                <li @click="openCreateModal">{{ $t('Create a ticket') }}</li>
                            </ul>
                        </nav>
                        <button class="btn btn-primary cancelButton" button-type="cancel-white">{{ $t('Cancel') }}</button>
                    </div>
                </div>
            </transition>
        </div>
        <SupportModal :form="form" :currentPage="currentPage" :isVisible="isSupportModalVisible"
            @closePopup="closeSupportModal" />
        <CModal alignment="center" :show.sync="showUsersGuide" class="users-guide-modal">

            <Userguide :parent="'helpMenu'" v-if="showUsersGuide" :isModal="true" :currentPage="currentPage"
                class="users-guide-content" />

            <template slot="footer">
                <button class="btn btn-primary" @click="closeUesrsGuideModal" button-type="cancel">Close</button>
            </template>
        </CModal>
    </div>
</template>

<script>
import { mdiHelpBoxMultipleOutline } from '@mdi/js';
import SupportModal from './SupportModal.vue';
import SvgIcon from '@jamescoyle/vue-icon';
import html2canvas from 'html2canvas';
import { ActiveUser } from '@/services/user';

export default {
    name: 'HelpMenu',
    components: {  SupportModal, SvgIcon },
    props: [],
    data() {
        return {
            showOverlay: false,
            currentPage: '',
            showUsersGuide: false,
            helpIcon: mdiHelpBoxMultipleOutline,
            isSupportModalVisible: false,
            form: null,
            userEmail: null
        };
    },
    mounted() {
    },
    methods: {
        toggleIcon(e) {
            this.userEmail = ActiveUser.user.email
            const target = e.target;
            if (!target.classList.contains('menu')) {
                this.showOverlay = !this.showOverlay;
            }

            const isBtn = target instanceof SVGElement || target.classList.contains('icon');

            if (isBtn) {
                html2canvas(document.body).then((canvas) => {
                    canvas.toBlob((blob) => {
                        const url = URL.createObjectURL(blob);

                        const a = new Date();
                        const filename = a.toISOString();

                        const form = new FormData();
                        form.append('file', blob, `Screenshot_${filename}.png`);
                        this.form = form;

                        URL.revokeObjectURL(url);
                    }, 'image/png');
                });
            }
        },
        openCreateModal() {
            this.isSupportModalVisible = true;
            this.setCurrenntPage();
        },
        closeSupportModal() {
            this.isSupportModalVisible = false;
        },
        setCurrenntPage() {
            this.currentPage = this.$router.currentRoute.hash.slice(1) || this.$router.currentRoute.name;
        },
        openUsersGuide() {
            this.showUsersGuide = true;
            this.setCurrenntPage();
        },
        closeUesrsGuideModal() {
            this.showUsersGuide = false;
        },
    },
};
</script>

<style scoped lang="scss">

.icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: var(--primary);
    z-index: 100000;
    text-align: center;
    line-height: 200px;
    cursor: pointer;

    height: 50px !important;
    width: 50px !important;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;

    &::v-deep {
        svg {
            color: white;
            transition: 0.3s ease;

            &:hover {
                transform: scale(1.2);
            }
        }
    }
}

.menu {
    position: absolute;
    right: 20px;
    bottom: 100px;
    min-width: 300px;
    height: 300px;
    padding: 10px 20px;
    background-color: #0060af;
    border-radius: 10px;
    color: white;
    transition: top 0.3s ease;
    z-index: 999;
    cursor: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
    line-height: 100%;

    &>li {
        &:hover {
            text-decoration: none;
        }
    }

    &>* {
        height: 30px;
        padding: 0 10px;
        cursor: pointer;
        text-align: left;
        position: relative;
        padding-right: 35px;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        transition: 0.5s ease;

        &:hover,
        &::v-deep:hover a {
            background-color: white;
            color: #3c4b64;

            &::before,
            &::after {
                background-color: #3c4b64;
            }

            &::before {
                transform: translateX(5px) rotate(45deg);
            }

            &::after {
                transform: translateX(5px) rotate(-45deg);
            }
        }

        &::before {
            content: '';
            position: absolute;
            top: 10px;
            right: 10px;
            width: 10px;
            background-color: white;
            height: 1px;
            transform: rotate(45deg);
            transition: 0.5s ease;
        }

        &::after {
            content: '';
            position: absolute;
            top: 17px;
            right: 10px;
            width: 10px;
            background-color: white;
            height: 1px;
            transform: rotate(-45deg);
            transition: 0.5s ease;
        }
    }
}

.router-link {
    color: white;
}

.menu-header {
    h4 {
        color: white;
        text-align: left;
        margin: 0;
    }
}

.menu-title {
    margin-bottom: 50px;

    h2 {
        text-align: left;
        color: white;
        margin: 0;
    }
}

nav {
    margin-top: 15px;
    border: 1px solid;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 998;
}

.users-guide-modal {
    &::v-deep {
        .modal-dialog {
            width: 80vw;
        }
    }
}

.users-guide-content {
    padding: 0 20px;
}

.users-guide-modal {
    &::v-deep {
        .modal-dialog {
            width: 80vw;
        }
    }
}

.users-guide-content {
    padding: 0 20px;
}

.cancelButton {
    background-color: #0071ce;
    color: #ffffff;

    &:hover {
        background-color: #ffffff;
        color: #0071ce;
    }
}
</style>
