import { AuthService } from "./auth";
import { UsersService } from "@/services/users";

const userKey = "web_adopt_user";
const accessTokenKey = "access_token";

class User {
  user = null;
  tokenBearer = "Bearer";

  get() {
    if (this.user) {
      return this.user;
    }
    const savedUser = localStorage.getItem(userKey);
    if (savedUser) {
      this.user = JSON.parse(savedUser);
      this.set(this.user);
      return this.user;
    }
  }

  clear() {
    const token = this.getToken();
    localStorage.removeItem(userKey);
    this.user = null;
    localStorage.removeItem(accessTokenKey);
    if (token) {
      AuthService.logout(token);
    }
  }

  async set(value) {
    localStorage.setItem(userKey, JSON.stringify(value));
    if (value.accessToken) {
      // lazy-table requires access_token
      localStorage.setItem(accessTokenKey, value.accessToken);
    }
    const userInfo = await UsersService.getActiveUserInfo();
    if (!userInfo?.id) {
      return;
    }
    const userData = { ...value, ...userInfo };
    localStorage.setItem(userKey, JSON.stringify(userData));
    this.user = userData;
  }

  getToken() {
    return this.get()?.accessToken;
  }

  getAuthorization() {
    return `${this.tokenBearer} ${this.getToken()}`;
  }

  setToken(token) {
    return this.set({ accessToken: token });
  }

  getLanguage() {
    return this.get()?.language;
  }

  setLanguage(language) {
    if (language !== this.getLanguage()) {
      return this.set({ ...this.get(), language });
    }
  }

  isAdministrator() {
    return this.get()?.is_superuser;
  }
}

export const ActiveUser = new User();
