<template>
  <div :class="{ 'hovered-node': isNodeOrParentHovered(node) }">
    <div style="display: flex; align-items: center; gap: 10px">
      <div
        :style="{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          cursor: 'pointer',
          marginBottom: '10px',
          marginLeft: depth + 'px',
          position: 'relative',
          //marginLeft: fontSize.marginLeft,
        }"
        @click="toggleLevel()"
      >
        <i
          v-if="hasChildren"
          :class="isExpanded ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"
          style="cursor: pointer; position: absolute; left: -20px"
        ></i>

        <h2
          :style="` font-size: ${fontSize}px!important `"
          :class="{
            'bold-text': node.parent_id === '' || node.parent_id === null,
            'regular-text': node.parent_id !== '' && node.parent_id !== null,
          }"
          @mouseover="setHoveredNode(node.id)"
          @mouseleave="clearHoveredNode"
        >
          {{ node.level_name }}
        </h2>
      </div>
      <div style="display: flex; gap: 15px">
        <i
          v-if="
            projectLevel
              ? doesHaveRight('modifyProjectLevels')
              : doesHaveRight('modifyOrganizationLevels')
          "
          v-c-tooltip="{
            content: $t('Rename Level'),
            placement: 'top',
          }"
          class="fa fa-pencil level-icons"
          :style="` font-size: ${fontSize - 5}px!important `"
          @click="openRenameLevelModal(node.level_name)"
        ></i>
        <i
          v-if="
            projectLevel
              ? doesHaveRight('addProjectLevels')
              : doesHaveRight('addOrganizationLevels')
          "
          v-c-tooltip="{
            content: $t('Add Level'),
            placement: 'top',
          }"
          class="fa fa-plus level-icons"
          :style="` font-size: ${fontSize - 5}px!important `"
          @click="openAddLevelModal()"
        ></i>
        <i
          v-c-tooltip="{
            content: `${$t('View Members')} (${node.members.length})`,
            placement: 'top',
          }"
          class="fa fa-users level-icons"
          :style="` font-size: ${fontSize - 5}px!important `"
          @click="openViewMembersModal()"
        ></i>
        <i
          v-if="
            projectLevel
              ? doesHaveRight('deleteProjectLevels')
              : doesHaveRight('deleteOrganizationLevels')
          "
          v-c-tooltip="{
            content: $t('Delete Level'),
            placement: 'top',
          }"
          class="fa fa-trash level-icons"
          :style="` font-size: ${fontSize - 5}px!important `"
          @click="openDeleteLevelModal()"
        ></i>
      </div>
    </div>
    <div v-if="hasChildren && isExpanded">
      <OrganizationViewTree
        v-for="child in node.children"
        :key="child.id"
        :node="child"
        :depth="depth + 35"
        :fontSize="fontSize > 20 ? fontSize - 5 : fontSize"
        :expandedLevels="expandedLevels"
        :projectLevel="projectLevel"
        :orgMembers="orgMembers"
        :allLevels="allLevels"
        :orgViewData="orgViewData"
        :allLevelsInOrgAndProject="allLevelsInOrgAndProject"
      />
    </div>
    <AddLevelsModal
      :show="showAddLevelModal"
      @close="showAddLevelModal = false"
      :parentId="node.id"
      :allLevels="allLevels"
      :projectLevel="projectLevel"
    />
    <DeleteLevelModal
      :show="showDeleteLevelModal"
      @close="showDeleteLevelModal = false"
      :node="node"
      :projectLevel="projectLevel"
    />
    <RenameLevelModal
      :show="showRenameLevelModal"
      @close="showRenameLevelModal = false"
      :node="node"
      :projectLevel="projectLevel"
      :currentLevelName="currentLevelName"
      :allLevelsInOrgAndProject="allLevelsInOrgAndProject"
    />
    <LevelMembersModal
      :show="showMembersModal"
      @close="showMembersModal = false"
      :node="node"
      :parentId="node.id"
      :orgMembers="orgMembers"
      :orgViewData="orgViewData"
      :projectLevel="projectLevel"
      :projectLevelOptions="projectLevelOptions"
      :projectData="projectData"
    />
  </div>
</template>

<script>
import AddLevelsModal from "../organizationSelect/modals/AddLevelsModal.vue";
import DeleteLevelModal from "../components/organizationPage/DeleteLevelModal.vue";
import RenameLevelModal from "../components/organizationPage/RenameLevelModal.vue";
import LevelMembersModal from "../components/organizationPage/LevelMembersModal.vue";
import { mapState } from "vuex";
export default {
  name: "OrganizationViewTree",
  components: {
    AddLevelsModal,
    DeleteLevelModal,
    RenameLevelModal,
    LevelMembersModal,
  },

  props: {
    node: Object,
    depth: Number,
    expandedLevels: Set,
    projectLevel: Boolean,
    fontSize: String,
    orgMembers: Array,
    allLevels: Set,
    orgViewData: Object,
    projectLevelOptions: Array,
    projectData: Array,
    allLevelsInOrgAndProject: Array,
  },
  data() {
    return {
      showAddLevelModal: false,
      showRenameLevelModal: false,
      showDeleteLevelModal: false,
      showMembersModal: false,
      currentLevelName: "",
      hoveredNodeId: null,
    };
  },
  mounted() {},

  computed: {
    ...mapState({
      selectedEvent: (state) => state.selectedEvent,
      selectedTraining: (state) => state.selectedTraining,
      userData: (state) => state.userData,
      currentOrganization: (state) => state.selectedOrg,
      currentProject: (state) => state.selectedProject,
      includedOrgs: (state) => state.includedOrgs,
      allOrgs: (state) => state.allOrgs,
    }),
    hasChildren() {
      return this.node.children && this.node.children.length > 0;
    },

    iconFontSize() {
      const baseSize = 20;
      const decreaseFactor = 1;
      const minSize = 5;
      let calculatedIconSize =
        baseSize - (this.depth > 0 ? this.depth * decreaseFactor : 0);
      calculatedIconSize = Math.max(calculatedIconSize, minSize);

      return calculatedIconSize + "px";
    },
    isExpanded() {
      return this.expandedLevels.has(this.node.level_name);
    },
  },
  methods: {
    doesHaveRight(right) {
      return this.$parent.doesHaveRight(right);
    },
    setHoveredNode(nodeId) {
      this.hoveredNodeId = nodeId;
    },
    clearHoveredNode() {
      this.hoveredNodeId = null;
    },
    isNodeOrParentHovered(node) {
      if (this.hoveredNodeId === null) {
        return false;
      }
      let currentNode = node;
      while (currentNode) {
        if (currentNode.id === this.hoveredNodeId) {
          return true;
        }
        currentNode = this.getParentNode(currentNode.parent_id);
      }
      return false;
    },

    toggleLevel() {
      this.expandedLevels.has(this.node.level_name)
        ? this.expandedLevels.delete(this.node.level_name)
        : this.expandedLevels.add(this.node.level_name);
    },
    openAddLevelModal() {
      this.showAddLevelModal = true;
    },
    openRenameLevelModal(levelName) {
      this.currentLevelName = levelName;
      this.showRenameLevelModal = true;
    },
    openDeleteLevelModal() {
      this.showDeleteLevelModal = true;
    },
    openViewMembersModal() {
      this.showMembersModal = true;
    },
    refreshData() {
      this.$parent.refreshData();
    },
    setIsLoadingTrue() {
      this.$parent.setIsLoadingTrue();
    },
    addMemberFromTable(memberRoles, selectedMember) {
      this.$parent.addMemberFromTable(memberRoles, selectedMember);
    },
  },
};
</script>

<style scoped>
.level-icons {
  margin-bottom: 15px;
  cursor: pointer;
}
.level-icons:hover {
  color: #0071ce;
}
.bold-text {
  font-weight: bold;
}

.regular-text {
  font-weight: normal;
}
.hovered-node {
  background-color: #f1f2f2;
}
</style>
