<template>
  <div>
    <CModal
      :show.sync="show"
      alignment="center"
      class="addMember"
      :draggable="false"
    >
      <template v-slot:header>
        <h3>{{ $t("Add member") }}</h3>
        <CButton @click="close">
          <i class="fa fa-times"></i>
        </CButton>
      </template>

      <div class="addMember modal-body" style="height: 200px">
        <div>
          <span style="font-size: 12px">{{ $t("Member") }}:</span>
          <el-select
            v-model="selectedMember"
            :options="processedOrgMembers"
            label="text"
            track-by="value"
            :placeholder="$t('Select A Member')"
          >
            <el-option
              v-for="item in processedOrgMembers.map((el) => ({
                value: el,
                label: el,
                id: el,
              }))"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div v-if="selectedMember" style="margin-top: 30px">
          <div v-if="!projectLevel">
            <CInput
              v-model="job_description"
              :placeholder="$t('Job description')"
            />
          </div>
          <div v-else>
            <el-select
              class="style-chooser"
              :id="'levelName' + index"
              v-model="job_description"
              label="text"
              track-by="value"
              :placeholder="$t('Select A Level')"
            >
              <el-option
                v-for="item in BackOfficeRole.map((el) => ({
                  value: el,
                  label: el,
                  id: el,
                }))"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
          <div
            v-if="!projectLevel"
            style="display: flex; gap: 30px; margin-top: 20px"
          >
            <div style="display: flex; gap: 20px">
              <span style="font-size: 15px">{{ $t("Director") }}</span>
              <input type="checkbox" v-model="admin" />
            </div>
            <div style="display: flex; gap: 20px">
              <span style="font-size: 15px">{{ $t("Team leader") }}</span>
              <input type="checkbox" v-model="responsible" />
            </div>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <CButton @click="close" class="link">{{ $t("Cancel") }}</CButton>
        <CButton
          color="primary"
          class="modal-confirm"
          @click="addMember"
          :disabled="isDisabled"
        >
          {{ $t("Save") }}
        </CButton>
      </template>
    </CModal>
  </div>
</template>
<script>
import vSelect from "vue-select";
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import "vue-select/dist/vue-select.css";
export default {
  data() {
    return {
      selectedMember: null,
      admin: false,
      responsible: false,
      job_description: "",
      levels: [],
    };
  },
  components: {
    vSelect,
    Treeselect,
  },
  props: {
    show: Boolean,
    node: Object,
    orgMembers: Array,
    projectLevel: Boolean,
    projectLevelOptions: Array,
    projectData: Array,
  },
  computed: {
    BackOfficeRole() {
      if (this.projectData) {
        return Object.keys(this.projectData.role_system.back_office);
      }
    },
    processedOrgMembers() {
      if (this.orgMembers) {
        const existingMembersSet = new Set(
          this.node.members.map(
            (member) => `${member.firstname} ${member.lastname}`
          )
        );

        return this.orgMembers
          .filter((member) => {
            const fullName = `${member.firstname} ${member.lastname}`;
            return !existingMembersSet.has(fullName);
          })
          .map((member) => `${member.firstname} ${member.lastname}`);
      }
    },
    isDisabled() {
      return !this.selectedMember || !this.job_description.trim();
    },
  },

  watch: {
    show: function (val) {
      if (val) {
      }
    },
  },
  methods: {
    close() {
      this.selectedMember = null;
      this.admin = false;
      this.responsible = false;
      this.job_description = "";

      this.$emit("close");
    },
    addMember() {
      const neededMember = this.orgMembers.find((member) => {
        return `${member.firstname} ${member.lastname}` === this.selectedMember;
      });
      const neededMemberData = {
        admin: this.admin,
        localisation: neededMember.localisation,
        email: neededMember.email,
        firstname: neededMember.firstname,
        job_description: this.job_description,
        lastname: neededMember.lastname,
        responsible: this.responsible,
        phone: neededMember.phone,
      };
      this.node.members.push(neededMemberData);
      const memberRoles = {
        job_description: this.job_description,
        admin: this.admin,
        responsible: this.responsible,
        level_name: this.node.level_name,
        roleType: this.projectLevel ? "Project" : "Organization",
      };
      this.$parent.addMemberFromTable(memberRoles, this.selectedMember);
      this.close();
    },
  },
};
</script>
<style scoped lang="scss">
:root {
  --vs-search-input-placeholder-color: #bdbdbd;
  --vs-font-size: 14px;
}

// .addMember.modal-body {
//   overflow: unset !important;
// }
</style>
