<template>
  <div>
    <CModal :show.sync="show" alignment="center">
      <template v-slot:header>
        <h3>{{ $t("Delete Level") }}</h3>
        <CButton @click="close">
          <i class="fa fa-times"></i>
        </CButton>
      </template>

      <div class="modal-body">
        {{ $t("Are you sure you want to delete the level") }}
        <b>{{ node.level_name }}</b
        >?
        {{
          $t(
            "This action will delete all the other levels inside of it if they exist"
          )
        }}.
      </div>
      <template v-slot:footer>
        <CButton @click="close" class="link">{{ $t("Cancel") }}</CButton>
        <CButton color="danger" class="modal-confirm" @click="deleteLevel">
          {{ $t("Delete") }}
        </CButton>
      </template>
    </CModal>
  </div>
</template>
<script>
import { OrganizationsService } from "@/services/organizations";
import vSelect from "vue-select";
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import "vue-select/dist/vue-select.css";
import { mapState } from "vuex";
import { ElLoading } from "element-plus";
import { showMessage } from "../../../../utils/helpers";
export default {
  data() {
    return {};
  },
  components: {
    vSelect,
    Treeselect,
  },
  computed: {
    ...mapState({
      selectedEvent: (state) => state.selectedEvent,
      selectedTraining: (state) => state.selectedTraining,
      userData: (state) => state.userData,
      currentOrganization: (state) => state.selectedOrg,
      currentProject: (state) => state.selectedProject,
      includedOrgs: (state) => state.includedOrgs,
      allOrgs: (state) => state.allOrgs,
      orgObj: (state) => state.orgObj,
    }),
  },
  props: {
    show: Boolean,
    node: Object,
    projectLevel: Boolean,
  },

  methods: {
    close() {
      this.$emit("close");
    },
    async deleteLevel() {
      // this.$parent.setIsLoadingTrue();
      const level = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });
      if (!this.projectLevel) {
        const { data, res } = await OrganizationsService.delete_level({
          org_name: this.orgObj.name,
          level_id: this.node.id,
          prj_name: this.currentProject.name,
          org_id: this.orgObj.id,
        });
        if (data.success) {
          console.log(this.orgObj);
          showMessage("success", data.message);
          this.$emit("close");
          this.$parent.refreshData();
        } else {
          showMessage("error", data.detail);
        }
      } else {
        const { data, res } = await OrganizationsService.delete_project_level({
          org_name:
            this.currentOrganization.name ||
            this.currentOrganization.organization,
          project_name: this.currentProject.name,
          level_id: this.node.id,
          org_id: this.orgObj.id,
        });
        if (data.success) {
          showMessage("success", data.message);
          this.$emit("close");
          this.$parent.refreshData();
        } else {
          showMessage("error", data.detail);
        }
      }
      level.close();
    },
  },
};
</script>

<style>
.modal-confirm {
  border-radius: 0px 0px 0px 25px;
}
</style>
