import { BASE_URL } from "@/constants/config";
import { request } from "@/utils/request";

export const USER_ENDPOINTS = {
  list: `${BASE_URL}api/users`,
  register: `${BASE_URL}auth/register`,
  me: `${BASE_URL}api/users/me`,
  detail: (id) => `${BASE_URL}users/${id}`,
  roles: `${BASE_URL}api/roles/`,
  advanced_roles: `${BASE_URL}api/roles/v2/`,
  changeLanguage: `${BASE_URL}api/users/change_language`,
  setState: (organizationId) =>
    `${BASE_URL}api/roles/v2/set_state/${organizationId}`,
  getPermissions: `${BASE_URL}api/users/permissions`,
};

class Users {
  async getActiveUserInfo() {
    const { data } = await request({
      url: USER_ENDPOINTS.me,
    });
    return data;
  }

  async getUser(id) {
    const { data } = await request({
      url: USER_ENDPOINTS.detail(id),
    });
    return data;
  }

  async createUser(userData) {
    const { data } = await request({
      url: USER_ENDPOINTS.register,
      method: "POST",
      body: userData,
    });
    return data;
  }

  async editUser(id, userData) {
    const { data } = await request({
      url: USER_ENDPOINTS.detail(id),
      method: "PATCH",
      body: userData,
    });
    return data;
  }

  async deleteUser(id) {
    return await request({
      url: USER_ENDPOINTS.detail(id),
      method: "DELETE",
    });
  }

  async getRoles() {
    const { data } = await request({
      url: USER_ENDPOINTS.roles,
    });
    return data;
  }

  async getAdvancedRoles() {
    const { data } = await request({
      url: USER_ENDPOINTS.advanced_roles,
    });
    return data;
  }
  async saveAdvancedRoles(role_data) {
    const { data } = await request({
      url: USER_ENDPOINTS.advanced_roles,
      method: "PUT",
      body: role_data,
    });
    return data;
  }

  async changeLanguage(newLanguage) {
    return await request({
      url: USER_ENDPOINTS.changeLanguage,
      method: "PUT",
      body: newLanguage,
    });
  }

  async setState(organizationId) {
    const { data } = await request({
      url: USER_ENDPOINTS.setState(organizationId),
      method: "POST",
    });
    return data;
  }
  async getPermissions() {
    const { data } = await request({
      url: USER_ENDPOINTS.getPermissions,
      method: "GET",
    });
    return data;
  }
}

export const UsersService = new Users();
