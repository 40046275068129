<template>
  <div>
    <CModal @hide="close" :show.sync="show" alignment="center">
      <template v-slot:header>
        <h3>{{ $t("Delete") }} {{ $t("Organization / Project") }}</h3>
        <CButton @click="close">
          <i class="fa fa-times"></i>
        </CButton>
      </template>
      <div class="modal-body">
        <div class="text">
          {{ $t("Are you sure you want to delete the project") }}
          <b
            ><span>
              {{
                orgToDelete ? orgToDelete.name || orgToDelete.organization : ""
              }}
              /{{ projectToDelete ? projectToDelete.name : "" }}</span
            ></b
          >? <br />
          {{
            $t(
              "This action will also delete users, and actions connected to this project."
            )
          }}
        </div>
      </div>

      <template slot="header-wrapper">
        <div class="HiddenWrapper" />
      </template>
      <template v-slot:footer>
        <CButton @click="close" class="link">{{ $t("Cancel") }}</CButton>
        <CButton
          @click="deleteOrg"
          color="danger"
          class="modal-confirm"
          data-testid="confirmDeleteOrg"
        >
          {{ $t("Delete") }}
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { OrganizationsService } from "@/services/organizations";
import { UsersService } from "@/services/users";

import { showMessage } from "../../../../utils/helpers";
import { mapState } from "vuex";
export default {
  name: "OrganizationDeleteModal",
  props: {
    show: Boolean,
    orgToDelete: String,
    projectToDelete: String,
  },
  computed: {
    ...mapState({
      orgsWithProjects: (state) => state.orgsWithProjects,
      currentOrganization: (state) => state.selectedOrg,
      currentProject: (state) => state.selectedProject,
      includedOrgs: (state) => state.includedOrgs,
      allOrgs: (state) => state.allOrgs,
    }),
  },
  methods: {
    close() {
      this.$emit("close");
    },

    async deleteOrg() {
      console.log("its this");
      const { data, res } = await OrganizationsService.delete({
        name: this.orgToDelete.name || this.orgToDelete.organizaion,
        project: this.projectToDelete.name,
        org_id: this.orgToDelete._id,
        prj_id: this.projectToDelete._id,
      });
      if (!data.success) {
        showMessage("error", "Error deleting Organization/Project.");
      } else {
        showMessage("success", "Successfully deleted Organization/Project.");
        this.close();
        location.reload();

        this.close();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.text {
  margin-top: 20px;
}

.header {
  font-size: 18px;
  font-weight: 600;
}

.modal-body::v-deep .modal-dialog {
  min-width: 50vw;
  transform: translateY(100%);
}

header.modal-header {
  border-bottom: none;
  display: none;
}

footer.modal-footer {
  border-top: none;
}
.modal-confirm {
  border-radius: 0px 0px 0px 25px;
}
</style>
