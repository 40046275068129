<template>
  <div>
    <CModal :show.sync="show" size="md">
      <template v-slot:header>
        <h3>Add a new project</h3>
        <CButton @click="closeUniqueMember">
          <i class="fa fa-times"></i>
        </CButton>
      </template>
      <div class="modal-body">
        <div>
          <label for="projectName">Project Name:</label>
          <CInput id="projectName" v-model="projectName" />
        </div>
      </div>
      <template v-slot:footer>
        <CButton @click="close" color="link">Close</CButton>
        <CButton color="primary" class="add-btn" @click="addProject"
          >Add Project</CButton
        >
      </template>
    </CModal>
  </div>
</template>

<script>
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { OrganizationsService } from "@/services/organizations";
import { showMessage } from "../../../../utils/helpers";
export default {
  data() {
    return {
      showAddRoleModal: false,
      roles: [],
      projectName: "",
    };
  },
  components: {
    Treeselect,
    vSelect,
  },
  props: {
    show: Boolean,
    selectedOrganization: String,
  },
  methods: {
    close() {
      this.projectName = "";
      this.$emit("close");
    },
    async addProject() {
      const { data, res } = await OrganizationsService.add_project({
        organization: this.selectedOrganization,
        project: this.projectName,
      });
      if (data.success) {
        // toast.success("Successfully Created Project", {
        //   position: toast.POSITION.BOTTOM_LEFT,
        // });
        showMessage("success", "Successfully Created Project");
        this.$parent.changeOrgName(this.selectedOrganization);
        this.$parent.changeProjectName(this.projectName);
        this.projectName = "";
        this.close();
        this.$parent.refreshEverything();
      }
    },
  },
  watch: {
    show: function (val) {
      if (val) {
      }
    },
  },
};
</script>
