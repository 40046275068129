import { request } from "@/utils/request";
import { BASE_URL } from "@/constants/config";
import { ActiveUser } from "./user";

export const FILES_ENDPOINTS = {
  upload: `${BASE_URL}api/bulk-files/upload/`,
  download: `${BASE_URL}api/bulk-files/download/`,
  delete: `${BASE_URL}api/bulk-files/delete/`,
  list: `${BASE_URL}api/bulk-files/list/`,
  availableFileTypes: `${BASE_URL}api/bulk-files/available_file_types/`,
  delete_file: `${BASE_URL}api/bulk-files/delete_file/`,
};

class Files {
  async removeFile(query) {
    console.log(query);
    const token = await ActiveUser.getToken();
    const { data } = await request({
      url: FILES_ENDPOINTS.delete,
      method: "DELETE",
      query,
      headers: {
        authorization: token,
      },
    });
    return data;
  }

  async fetchAvailableFileTypes() {
    const { data } = await request({
      url: FILES_ENDPOINTS.availableFileTypes,
    });
    return data;
  }

  async fetchFiles() {
    const { data } = await request({
      url: FILES_ENDPOINTS.list,
    });
    return data;
  }

  uploadFile(file, fileId, onProgress = null) {
    return new Promise((resolve, reject) => {
      const uploadedFilename = file.name;
      const formData = new FormData();
      // Files bigger then 80MB aren't supported with current nginx config
      if (file.size > 80000000) {
        reject("File is too big");
      }
      formData.append("file", file, uploadedFilename);
      formData.append("file_id", fileId);
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          onProgress && onProgress(100);
          if (xhr.status === 200) {
            resolve("File was uploaded successfully.");
          } else {
            // redirectIfNotAuthenticated(xhr);
            try {
              const response = JSON.parse(xhr.responseText);
              reject(
                `Error occurred during upload. ${response?.detail?.message}.`
              );
            } catch {
              reject("Error occurred during upload.");
            }
          }
        }
      };

      xhr.onprogress = function (e) {
        if (e.lengthComputable) {
          onProgress && onProgress((e.loaded / e.total) * 100);
        }
      };
      onProgress && onProgress(0);

      xhr.open("post", FILES_ENDPOINTS.upload);
      xhr.setRequestHeader("authorization", `Bearer ${ActiveUser.getToken()}`);
      xhr.send(formData);
    });
  }

  async delete_file({ filename }) {
    const { data } = await request({
      url: FILES_ENDPOINTS.delete_file,
      method: "DELETE",
      skipRedirect: true,
      body: {
        filename,
      },
    });
    return data;
  }
}

export const FilesService = new Files();
