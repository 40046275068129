<template>
  <div>
    <CModal @hide="close" :show.sync="show" alignment="center">
      <template v-slot:header>
        <h3>{{ $t("Rename") }} {{ $t("Organization") }}/{{ $t("Project") }}</h3>
        <CButton @click="close">
          <i class="fa fa-times"></i>
        </CButton>
      </template>
      <div class="modal-body">
        <div style="display: flex; gap: 20px">
          <div>
            <label for="orgName">{{ $t("Organization") }}:</label>
            <CInput
              id="orgName"
              v-model="newOrgName"
              data-testid="organizationEditNameInput"
            />
          </div>
          <div>
            <label for="projectName">{{ $t("Project") }}:</label>
            <CInput
              id="projectName"
              v-model="newProjectName"
              data-testid="projectEditNameInput"
            />
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <CButton @click="close" class="link">{{ $t("Cancel") }}</CButton>
        <CButton
          data-testid="edit-org-names"
          @click="editOrgName"
          color="primary"
          class="modal-confirm"
          :disabled="!newOrgName.trim() || !newProjectName.trim()"
        >
          {{ $t("Save") }}
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { OrganizationsService } from "@/services/organizations";
import { showMessage } from "../../../../utils/helpers";
import { mapState } from "vuex";

export default {
  name: "OrganizationEditModal",
  data() {
    return {
      newOrgName: "",
      newProjectName: "",
    };
  },
  computed: {
    ...mapState({
      userData: (state) => state.userData,
      currentOrganization: (state) => state.selectedOrg,
      currentProject: (state) => state.selectedProject,
      includedOrgs: (state) => state.includedOrgs,
    }),
  },
  props: {
    show: Boolean,
    orgToEdit: String,
    projectToEdit: String,
  },
  watch: {
    show() {
      console.log("thisorgToEdit", this.orgToEdit);
      console.log("projectToEdit", this.projectToEdit);

      this.newOrgName = this.orgToEdit.name || this.orgToEdit.organization;
      this.newProjectName = this.projectToEdit.name;
    },
  },
  methods: {
    close() {
      this.newOrgName = "";
      this.newProjectName = "";
      this.$emit("close");
    },

    async editOrgName() {
      let orgNameChanged =
        this.newOrgName !==
        (this.orgToEdit.name || this.orgToEdit.organization);
      let projectNameChanged = this.newProjectName !== this.projectToEdit.name;
      let orgNameToSend = orgNameChanged ? this.newOrgName : "";
      let projectNameToSend = projectNameChanged ? this.newProjectName : "";
      let exists = false;

      for (let org of this.includedOrgs.data) {
        if (
          org.organization === this.newOrgName ||
          org.name === this.newOrgName
        ) {
          for (let project of org.projects) {
            if (project.name === this.newProjectName) {
              exists = true;
              break;
            }
          }
        }
        if (exists) {
          break;
        }
      }
      if (exists) {
        showMessage("error", "Organization/Project already exists");
        return;
      }
      const { data, res } = await OrganizationsService.edit_name({
        org_name: this.orgToEdit.name || this.orgToEdit.organization,
        project_name: this.projectToEdit.name,
        new_org_name: orgNameToSend,
        new_project_name: projectNameToSend,
        org_id: this.orgToEdit._id,
        prj_id: this.projectToEdit._id,
      });

      // if (data.success) {
      showMessage("success", "Organization/Project name changed successfully");
      const newOrgs =
        await OrganizationsService.list_organizations_and_projects();
      this.$store.commit("setIncludedOrgs", newOrgs.data);
      // this.includedOrgs.data.forEach((org) => {
      //   if (org.organization === this.orgToEdit) {
      //     if (orgNameChanged) {
      //       if (org.organization) org.organization = this.newOrgName;
      //       if (org.name) org.name = this.newOrgName;
      //     }

      //     org.projects.forEach((project) => {
      //       if (project.name === this.projectToEdit) {
      //         if (projectNameChanged) project.name = this.newProjectName;
      //       }
      //     });
      //   }
      // });
      this.$emit("close");
      if (this.$parent.refreshEverything()) this.$parent.refreshEverything();
    },
  },
};
</script>

<style scoped lang="scss">
.inputWrapper {
  border-bottom: 1px solid #9d9d9d;
}

input {
  border: none;
  width: 100%;
  padding: 5px 5px 5px 0px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
}
.header {
  font-size: 18px;
  font-weight: 600;
}

.inputHeader {
  font-size: 12px;
}
.modal-body::v-deep .modal-dialog {
  min-width: 50vw;
  transform: translateY(100%);
}

header.modal-header {
  border-bottom: none;
  display: none;
}

footer.modal-footer {
  border-top: none;
}
.modal-confirm {
  width: max-content;
}
</style>
