import { defineStore } from "pinia";
import { UsersService } from "../services/users";

export const useUserStore = defineStore("useUserStore", {
  state: () => ({
    activeUser: {},
    currentLevel: null,
    userPoints: null,
    teamPoints: null,
    usersProgress: null,
    usersTeamProgress: null,
    allRolePoints: null,
    currentPermissions: [],
    isAdminUser: false,
    blobToken: null,
    userTrainingPoints: null,
    teamTrainingPoints: null,
    userTrainingProgress: null,
  }),
  actions: {
    async getActiveUserInfo() {
      const { data } = await UsersService.getActiveUserInfo();
      if (data) {
        this.activeUser = data;
      }
    },
    setCurrentLevel(currentLevel) {
      this.currentLevel = currentLevel;
    },
    setUserPoints(userPoints) {
      this.userPoints = userPoints;
    },
    setTeamPoints(teamPoints) {
      this.teamPoints = teamPoints;
    },
    setUsersProgress(usersProgress) {
      this.usersProgress = usersProgress;
    },
    setUsersTeamProgress(usersTeamProgress) {
      this.usersTeamProgress = usersTeamProgress;
    },
    setAllRolePoints(allRolePoints) {
      this.allRolePoints = allRolePoints;
    },
    setCurrentPermissions(currentPermissions) {
      this.currentPermissions = currentPermissions;
    },
    setIsAdminUser(isAdminUser) {
      this.isAdminUser = isAdminUser;
    },
    setBlobToken(blobToken) {
      this.blobToken = blobToken;
    },
    setUserTrainingPoints(userTrainingPoints) {
      this.userTrainingPoints = userTrainingPoints;
    },
    setTeamTrainingPoints(teamTrainingPoints) {
      this.teamTrainingPoints = teamTrainingPoints;
    },
    setUserTrainingProgress(userTrainingProgress) {
      this.userTrainingProgress = userTrainingProgress;
    },
  },
});
