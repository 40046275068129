<template>
  <router-view>
  </router-view>
  <HelpMenu v-if="isUserLoggedIn" />
</template>

<script>
import HelpMenu from "@/components/HelpMenu.vue";
import { ActiveUser } from "@/services/user";
import { mapState } from 'vuex';
export default {
  name: "App",
  components: { HelpMenu },
  computed: {
    ...mapState(['isUserLoggedIn']),
  },
  watch: {
    $route(to) {
      document.title =
        to.meta.title ||
        to.params[to.meta.paramKey] ||
        to.meta.label ||
        to.name ||
        "Mazars";
    },
  },
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
</style>
