div
<template>
  <div class="reportingWrapper">
    <div>
      <div
        class="reporting-top"
        :style="{ marginBottom: bubbleView == 0 ? '0' : '36px' }"
      >
        <h1 :style="isMobile ? 'font-size:2em;' : 'font-size: 3em;'">
          {{ $t("Reporting") }}
        </h1>
        <div class="topOptions" v-if="bubbleView == 0">
          <div class="topOptions-filter">
            <label for="phase">{{ $t("Phase") }}</label>

            <el-select
              v-model="phase"
              :placeholder="$t('Select Phase')"
              clearable
              collapse-tags
              collapse-tags-tooltip
              multiple
              filterable
              :disabled="isLoading"
            >
              <el-option
                v-for="item in phaseOptions"
                :key="item.code"
                :label="item.code"
                :value="item.code"
              />
            </el-select>
          </div>
          <div class="topOptions-filter" v-if="bubbleView == 0">
            <label for="category">{{ $t("Category") }}</label>
            <!-- <v-select
              class="style-chooser"
              v-model="category"
              :options="categoryOptions.map((item) => item.code)"
              label="text"
              track-by="value"
              :placeholder="$t('Select Category')"
              taggable
              push-tags
              :disabled="isLoading"
            ></v-select> -->
            <el-select
              v-model="category"
              :placeholder="$t('Select Category')"
              clearable
              collapse-tags
              collapse-tags-tooltip
              multiple
              filterable
              :disabled="isLoading"
            >
              <el-option
                v-for="item in categoryOptions"
                :key="item.code"
                :label="item.code"
                :value="item.code"
              />
            </el-select>
          </div>
          <div class="topOptions-filter">
            <label for="category">{{ $t("Level") }}</label>
            <!-- <v-select
              class="style-chooser"
              v-model="level"
              :options="sortedLevelOptions.map((item) => item.code)"
              label="text"
              track-by="value"
              :placeholder="$t('Select Level')"
              taggable
              push-tags
              :disabled="isLoading"
            ></v-select> -->
            <el-select
              v-model="level"
              :placeholder="$t('Select Level')"
              clearable
              collapse-tags
              collapse-tags-tooltip
              multiple
              filterable
              :disabled="isLoading"
            >
              <el-option
                v-for="item in sortedLevelOptions"
                :key="item.code"
                :label="item.code"
                :value="item.code"
              />
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <div class="sections--panel">
      <div
        v-for="(item, index) in sections"
        :key="index"
        @click="setSection(item.id)"
        :class="['section--btn', bubbleView == item.id && 'active']"
        style="cursor: pointer; margin-bottom: 10px"
      >
        {{ $t(item.label) }}
      </div>
    </div>
    <div class="mainArea">
      <ContentLoader
        v-if="isLoading"
        :speed="2"
        primaryColor="rgb(230, 230, 230)"
        secondaryColor="rgb(240, 240, 240)"
        class="p-1"
      ></ContentLoader>
      <div
        v-else
        id="table-container"
        class="levelsStyles"
        style="flex: 1"
        v-if="bubbleView == 0"
      >
        <div class="filterDropdown" @click="handleCollapseExpandAll">
          {{ expandAll ? $t("Collapse all") : $t("Expand all") }}
          <i
            :class="{
              'fa fa-caret-up': expandAll,
              'fa fa-caret-down': !expandAll,
            }"
            style="font-size: 30px"
            v-c-tooltip="{
              content: expandAll ? $t('Collapse all') : $t('Expand all'),
              placement: 'top',
            }"
          ></i>
        </div>
        <div class="levels">
          <ReportingTable
            v-for="child in orgData"
            :key="child.id"
            :node="child"
            :data="data"
            :expandedLevels="expandedLevels"
            @show-details-modal="openDetailsModal"
            :allLevels="allLevels"
            :depth="0"
          />
          <hr style="margin: 20px 0" />
          <h3>Project Levels</h3>
          <ReportingTable
            v-for="child in projectData"
            :key="child.id"
            :node="child"
            :data="data"
            :expandedLevels="expandedLevels"
            @show-details-modal="openDetailsModal"
            :allLevels="allLevels"
            :depth="0"
          />
        </div>
      </div>
      <div class="chartContainer">
        <ReportingBubble
          v-if="bubbleView == 0"
          :isLoading="isLoading"
          id="chart-container"
          :data="allData"
        />

        <ReportingBubbleTraining
          v-else
          :isLoading="isLoading"
          id="chart-container"
          :data="allTrainingData"
        />
      </div>
    </div>
    <DetailsModal
      :show="showDetailsModal"
      :selectedItem="selectedItem"
      :allActions="allActions"
      @close="showDetailsModal = false"
    />
  </div>
</template>

<script>
import { ReportingService } from "@/services/reporting";
import { ActionsService } from "@/services/actions";
import ReportingTable from "./components/ReportingTable.vue";
import ReportingBubble from "./components/ReportingBubble.vue";
import ReportingBubbleTraining from "./components/ReportingBubbleTraining.vue";
import vSelect from "vue-select";
import "vue3-treeselect/dist/vue3-treeselect.css";
import "vue-select/dist/vue-select.css";
import DetailsModal from "./components/DetailsModal.vue";
import { OrganizationsService } from "../../services/organizations";
import OrganizationSelect from "../organization/organizationSelect/OrganizationSelect.vue";
import { ContentLoader } from "vue-content-loader";
import { showMessage } from "../../utils/helpers";
import domtoimage from "dom-to-image";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import { mapState } from "vuex";
import html2canvas from "html2canvas";
import { useGlobalStore } from "../../stores/store";
import { mapWritableState } from "pinia";
import { ElLoading } from "element-plus";

export default {
  components: {
    ContentLoader,
    OrganizationSelect,
    DetailsModal,
    vSelect,
    ReportingTable,
    ReportingBubble,
    ReportingBubbleTraining,
    OrganizationSelector,
  },
  data() {
    return {
      sections: [
        { id: 0, label: "Actions" },
        { id: 1, label: "Trainings" },
      ],
      viewMode: "table",
      phase: [],
      category: [],
      level: [],
      phaseOptions: [],
      categoryOptions: [],
      levelOptions: [],
      showDetailsModal: false,
      selectedItem: null,
      expandAll: false,
      expandedLevels: new Set(),
      allLevels: new Set(),
      data: [],
      trainingData: [],
      allData: [],
      allTrainingData: [],
      orgData: [],
      projectData: [],
      orgList: [],
      isLoading: true,
      isCapturingScreenshot: false,
      loading: false,
      showOptions: false,
      actionsDetails: [],
      allActions: [],
      staticAllData: [],
      bubbleView: 0,
    };
  },
  async mounted() {
    this.refreshEverything();
  },

  watch: {
    phase() {
      this.getCategories();
      this.getData();
    },
    category() {
      this.getData();
    },
    level() {
      if (this.bubbleView === 0) {
        this.filterDataByLevel(this.staticAllData, this.level);
      } else {
        this.filterTrainingDataByLevel(this.allTrainingData, this.level);
      }
    },
  },
  computed: {
    ...mapState({
      selectedEvent: (state) => state.selectedEvent,
      selectedTraining: (state) => state.selectedTraining,
      userData: (state) => state.userData,
      currentOrganization: (state) => state.selectedOrg,
      currentProject: (state) => state.selectedProject,
      includedOrgs: (state) => state.includedOrgs,
      allOrgs: (state) => state.allOrgs,
    }),
    sortedLevelOptions() {
      return [...this.levelOptions].sort((a, b) =>
        a.value.localeCompare(b.value)
      );
    },
    ...mapWritableState(useGlobalStore, {
      isMobile: "isMobile",
    }),
  },
  methods: {
    setSection(id) {
      this.bubbleView = id;
    },
    filterDataByLevel(data, level) {
      if (!level) return (this.allData = this.staticAllData);
      let result = [];
      function searchLevel(items) {
        items.forEach((item) => {
          // Check if `level` is an array or string, and handle accordingly
          if (Array.isArray(level)) {
            // Check if any element of `level` exists in item.name
            if (level.some((lvl) => item.name.includes(lvl))) {
              result.push(item);
            }
          } else if (item.name.includes(level)) {
            result.push(item);
          }
          if (item.children && item.children.length) {
            searchLevel(item.children);
          }
        });
      }
      searchLevel(data);

      this.allData = result;
    },
    async refreshEverything() {
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });
      this.isLoading = true;
      await this.getData();
      await this.getTrainingData();
      this.level = [];
      this.category = [];
      this.phase = [];
      await this.getLevels();
      await this.getPhases();
      await this.getCategories();
      await this.getActionsList();
      this.isLoading = false;
      loading.close();
    },
    async getActionsList() {
      const payload = {
        organization:
          this.currentOrganization.name ||
          this.currentOrganization.organization,
        project: this.currentProject.name,
        role: this.selectedRole,
        user: [],
        view: "Grouped",
      };
      const { data } = await ActionsService.list_actions(payload);

      if (!data.success) {
        showMessage("error", "Error getting actions list data: " + data.detail);
      } else {
        this.allActions = data.data;
      }
    },
    getFormattedDate(date) {
      const formattedDate = date.split("/").reverse().join("-");

      const parsedDate = new Date(formattedDate);

      const day = parsedDate.getDate().toString().padStart(2, "0");

      const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
      const year = parsedDate.getFullYear();

      return `${day}-${month}-${year}`;
    },

    toggleOptions() {
      this.showOptions = !this.showOptions;
    },
    openDetailsModal(selectedItem) {
      this.selectedItem = selectedItem;
      this.showDetailsModal = true;
    },
    saveAsPng() {
      const element = document.querySelector(".bubbleContainer");

      if (!element) {
        console.error("Element with class 'bubbleContainer' not found");
        return;
      }

      this.isCapturingScreenshot = true;
      element.style.backgroundColor = "white";

      html2canvas(element)
        .then((canvas) => {
          const dataUrl = canvas.toDataURL("image/png");

          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = "chart.png";
          link.click();

          showMessage("success", "File successfully saved");
        })
        .catch((error) => {
          console.error("Error capturing screenshot:", error);
          showMessage("error", "Error capturing screenshot");
        })
        .finally(() => {
          this.isCapturingScreenshot = false;
          element.style.backgroundColor = "";
        });
    },

    handleCollapseExpandAll() {
      this.expandAll = !this.expandAll;
      this.setExpandedLevels(this.orgData, this.expandAll);
      this.setExpandedLevels(this.projectData, this.expandAll);
    },
    setExpandedLevels(data, expand) {
      for (let item of data) {
        if (expand) {
          this.expandedLevels.add(item.name);
        } else {
          this.expandedLevels.delete(item.name);
        }
        if (item.children && item.children.length > 0) {
          this.setExpandedLevels(item.children, expand);
        }
      }
    },
    async getData() {
      try {
        const { data, res } = await ReportingService.get_data({
          project: this.currentProject._id,
          level: this.level,
          phase: this.phase,
          category: this.category,
        });

        if (!data.success) {
          showMessage("error", "Error getting data: " + data.detail);
          this.orgData = [];
          this.projectData = [];
        } else {
          this.orgData = data.data;
          this.allData.forEach((item) => {
            this.expandedLevels.add(item.name);
          });
          this.projectData = data.data2;
          this.projectData.forEach((item) => {
            this.expandedLevels.add(item.name);
          });
          this.allData = this.orgData.concat(this.projectData);
          this.staticAllData = this.orgData.concat(this.projectData);
        }
      } catch (error) {
        showMessage("error", "An error occurred while fetching data.");
        console.error("Error in getData:", error);
      }
    },
    async getTrainingData() {
      try {
        const { data, res } = await ReportingService.get_training_data({
          project: this.currentProject._id,
        });

        if (!data.success) {
          showMessage("error", "Error getting training data: " + data.detail);
        } else {
          const orgTrainingData = data.data;
          const projectTrainingData = data.data2;
          this.allTrainingData = orgTrainingData.concat(projectTrainingData);
        }
      } catch (error) {
        showMessage("error", "An error occurred while fetching training data.");
        console.error("Error in getTrainigData:", error);
      }
    },
    async getPhases() {
      this.phase = [];
      const { data, res } = await ReportingService.get_phases({
        organization:
          this.currentOrganization.name ||
          this.currentOrganization.organization,
        project: this.currentProject.name,
      });
      if (!data.success) {
        alert("Error getting list of phases: " + data.detail);
      } else {
        const phaseOptions = [];
        const phaseOptionsTemp = data.data;

        phaseOptionsTemp.forEach((phase) => {
          phaseOptions.push({
            code: `${phase}`,
            value: `${phase}`,
          });
        });

        phaseOptions.sort((a, b) =>
          a.value.localeCompare(b.value.toLowerCase())
        );

        this.phaseOptions = [...phaseOptions];
      }
    },
    async getCategories() {
      this.category = [];
      const { data, res } = await ReportingService.get_categories({
        organization:
          this.currentOrganization.name ||
          this.currentOrganization.organization,
        project: this.currentProject.name,
        phase: this.phase,
      });
      if (!data.success) {
        alert("Error getting list of categories: " + data.detail);
      } else {
        const categoryOptions = [];
        const categoryOptionsTemp = data.data;

        categoryOptionsTemp.forEach((category) => {
          categoryOptions.push({
            code: `${category}`,
            value: `${category}`,
          });
        });

        categoryOptions.sort((a, b) =>
          a.value.localeCompare(b.value.toLowerCase)
        );

        this.categoryOptions = [...categoryOptions];
      }
    },
    async getLevels() {
      const { data, res } = await ReportingService.get_levels({
        organization:
          this.currentOrganization.name ||
          this.currentOrganization.organization,
        project: this.currentProject.name,
      });
      if (!data.success) {
        alert("Error getting list of levels: " + data.detail);
      } else {
        const levelOptions = [];
        const levelOptionsTemp = data.data;
        levelOptionsTemp.forEach((level) => {
          levelOptions.push({
            code: `${level}`,
            value: `${level}`,
          });
        });
        this.levelOptions = [...levelOptions];
      }
    },

    toggleEditModal(orgName, projectName) {
      this.orgToEdit = orgName;
      this.chosenProjectToEdit.organization = orgName;
      this.chosenProjectToEdit.project = projectName;
      this.showEditModal = !this.showEditModal;

      if (!this.showEditModal) {
        this.chosenProjectToEdit = {
          organization: "",
          project: "",
        };
      }
    },

    toggleDeleteModal(orgName, projectName) {
      this.orgToDelete = orgName;
      this.chosenProjectToDelete = projectName;
      this.showDeleteModal = true;
    },

    toggleCreateModal() {
      this.showCreateModal = !this.showCreateModal;
    },
  },
};
</script>

<style>
body,
html {
  height: 100%;
  overflow: hidden;
}
.dropdown-toggle:not(.dropdown-dark):not(.dropdown-white) {
  display: flex;
  align-items: center;
  width: max-content;
  border-radius: 0px 0px 0px 25px;
  padding: 8px 20px;
}

.style-chooser .vs__selected-options {
  display: flex;
  align-items: center;
  overflow: hidden;
  max-height: 28px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.filter {
  cursor: pointer;
  /* border-bottom: 2px solid #ccc; */
  font-size: 16px;
  font-weight: 400;
  padding: 0px 10px 0px 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.filter.active {
  color: #0071ce;
  border-bottom: 2px solid #0071ce;
  font-weight: 700;
}

.save-btn {
  .dropdown {
    .dropdown-toggle:not(.dropdown-dark):not(.dropdown-white) {
      height: 46px !important;
      padding: 10px 24px 10px 32px;
    }
  }
  & > div {
    padding: 0 0 0 10px;
  }
}

.orgProjectOption {
  padding: 10px;
  cursor: pointer;
}
.repTitle {
  padding-left: 0;
  text-align: left;
  width: 100%;
}

.topOptions {
  margin-left: 15px;
  display: flex;
  align-items: flex-end;
  gap: 10px;
  margin-bottom: 30px;
}

.topOptions-filter {
  width: 200px;
}
.mainArea {
  display: flex;
  justify-content: space-between;
  /*padding-right: 30px;*/
  max-height: calc(100vh - 250px);
  overflow: hidden;
  margin-right: 15px;
  gap: 20px;
}
.levels,
.levelsStyles {
  margin-top: 0;
}
.levelsStyles {
  border: 1px solid #c3c3c3;
  background: #ffffff;
  width: auto;
}
.filterDropdown {
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 9px 0 9px 15px;
  border-bottom: 1px solid #c3c3c3;
}
.levels {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  margin-left: 15px;
  padding-bottom: 20px;
}
.chartContainer {
  flex: 3;
  max-height: calc(100vh - 100px);
  overflow: hidden;
}
@media (max-width: 870px) {
  .topOptions {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 30px;
    width: 100%;
    padding-right: 20px;
  }
  .topOptions-filter {
    width: 49%;
  }
}
@media (max-width: 600px) {
  .topOptions-filter {
    width: 100%;
  }
  .save-btn {
    margin-top: 20px;
  }
}
@media (max-width: 970px) {
  .reportingWrapper {
    margin-top: 50px;
  }
}
@media (max-width: 650px) {
  .reportingWrapper {
    margin-top: 0;
  }
}
@media (max-width: 1296px) {
  .reporting-top {
    display: flex;
    flex-direction: column;
    margin-left: 0;
  }
  .topOptions {
    margin-left: auto;
  }
  .mainArea {
    max-height: calc(100vh - 295px);
  }
}
@media (max-width: 870px) {
  .mainArea {
    display: flex;
    flex-direction: column;
    height: auto;
    overflow-y: auto;
    max-height: calc(100vh - 340px);
  }

  .chartContainer {
    width: 100%;
    max-width: unset;
    max-height: 500px;
    overflow: visible;
  }
}

.reporting-top {
  margin-right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.style-chooser .vs__dropdown-toggle {
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  padding: 0 0 4px;
  background: none;
  border-bottom: var(--vs-border-width) var(--vs-border-style)
    var(--vs-border-color) !important;
  border-radius: 0;
  white-space: normal;
}

.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__clear,
.vs--disabled .vs__search,
.vs--disabled .vs__selected,
.vs--disabled .vs__open-indicator {
  background: none;
}
.style-chooser .vs__actions svg {
  transform: scale(0.7);
}
.sections--panel {
  display: flex;
  gap: 20px;
}
.section--btn {
  border: none;
  background: none;
  padding: 5px 0;
  outline: none;
}
.section--btn.active {
  color: #0071ce;
  position: relative;
}
.section--btn.active::before {
  content: "";
  width: 100%;
  height: 3px;
  background-color: #0071ce;
  position: absolute;
  bottom: 0;
}
</style>

<style scoped>
::v-deep .el-select__wrapper {
  height: 40px;
  border-radius: 0;
  box-shadow: none;
  border: 1px solid #c3c3c3;
}

::v-deep .el-input__wrapper {
  border-radius: 0;
  box-shadow: none;
  border: 1px solid #c3c3c3;
}
::v-deep .el-textarea__inner {
  border-radius: 0;
}

::v-deep .el-select__selection {
  flex-wrap: nowrap;
}
</style>
