<template>
  <div>
    <CModal id="addOrgModal" @hide="close" :show.sync="show" alignment="center">
      <template v-slot:header>
        <h3>{{ $t("Add new organization") }}</h3>
        <CButton @click="close">
          <i class="fa fa-times"></i>
        </CButton>
      </template>
      <div class="modal-body">
        <div style="display: flex; gap: 20px">
          <div>
            <label for="orgName">{{ $t("Organization Name") }}:</label>
            <CInput
              id="orgName"
              v-model="newOrgName"
              data-testid="organizationNameInput"
            />
          </div>
          <div>
            <label for="projectName">{{ $t("Project Name") }}:</label>
            <CInput
              id="projectName"
              v-model="newProjectName"
              data-testid="projectNameInput"
            />
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <CButton @click="close" class="link">{{ $t("Cancel") }}</CButton>
        <CButton
          data-testid="createNewOrgButton"
          style="border-radius: 0 0 0 25px"
          @click="createOrganization"
          color="primary"
          class="modal-create"
          :disabled="newOrgName.trim().length==0 || newProjectName.trim().length==0"
        >
          {{ $t("Add") }}
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { showMessage } from "@/utils/helpers";
import { OrganizationsService } from "@/services/organizations";
import { mapState } from "vuex";
export default {
  name: "OrganizationCreateModal",
  data() {
    return {
      newOrgName: "",
      newProjectName: "",
      showAddOrgModal: false,
    };
  },

  props: {
    show: Boolean,
  },
  computed: {
    ...mapState({
      userData: (state) => state.userData,
      currentOrganization: (state) => state.selectedOrg,
      currentProject: (state) => state.selectedProject,
      includedOrgs: (state) => state.includedOrgs,
      allOrgs: (state) => state.allOrgs,
    }),
  },
  methods: {
    close() {
      this.newOrgName = "";
      this.newProjectName = "";
      this.$emit("close");
    },
    async createOrganization() {
      //  const orgExists = this.allOrgs.data.some(
      //   (org) =>
      //    org.organization.trim().toLowerCase().replace(/\s/g, '') === this.newOrgName.trim().toLowerCase().replace(/\s/g, '') &&
      //    org.projects[0].name.trim().toLowerCase().replace(/\s/g, '') === this.newProjectName.trim().toLowerCase().replace(/\s/g, '')
      //  );
      //  if (orgExists) {
      //     showMessage("error", "Organization already exists!");
      //     return;
      //   }
      const { data, res } = await OrganizationsService.create({
        name: this.newOrgName,
        project: this.newProjectName,
      });
      if (!data.success) {
        showMessage("error", data.detail);
      } else {
        const newOrgs =
          await OrganizationsService.list_organizations_and_projects();
        this.$store.commit("setIncludedOrgs", newOrgs.data);
        showMessage("success", "Successfully Created Organization");
        this.close();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.text {
  margin-top: 20px;
}

.header {
  font-size: 18px;
  font-weight: 600;
}

.modal-body::v-deep .modal-dialog {
  min-width: 50vw;
  transform: translateY(100%);
}

header.modal-header {
  border-bottom: none;
  display: none;
}

footer.modal-footer {
  border-top: none;
}
</style>
