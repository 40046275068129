<template>
	<Treeselect
		:value="computedValue"
		:style="getStyles()"
		:valueFormat="valueFormat"
		:options="computedOptions"
		:clearable="clearable"
		@input="handleInput"
		@close="handleClose"
		@deselect="handleDeselect"
		:multiple="multiple"
		:limit="limit"
		:placeholder="placeholder"
		v-bind="$attrs"
		v-on="$listeners"
	>
		<label
			slot="option-label"
			slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
			:class="labelClassName"
			:title="node.label"
		>
			{{ node.label }}

		</label>
	</Treeselect>
</template>

<script>
    import Treeselect from 'vue3-treeselect'
  // import the styles
const colors = {
	primary: '#F5F6F8',
	white: '#FFFFFF',
};

export default {
	name: 'SelectUI',
	components: { Treeselect },
	props: {
		value: {
			type: [String, Number, Array, Object],
		},
		valueFormat: {
			type: String,
			default: 'id',
		},
		color: {
			type: String,
			default: 'primary',
		},
		clearable: {
			Type: Boolean,
			default: true,
		},
		options: {
			type: [Array, Function],
		},
		input: {
			type: Function,
		},
		close: {
			type: Function,
		},
		deselect: {
			type: Function,
		},
		multiple: {
			type: Boolean,
		},
		limit: {
			type: Number,
			default: 1,
		},
		placeholder: {
			type: String,
		},
	},
	computed: {
		computedValue() {
			return this.value;
		},
		computedOptions() {
			return typeof this.options === 'function' ? this.options() : this.options;
		},
	},
	methods: {
		getStyles() {
			return {
				'--select-color': colors[this.color],
			};
		},
		handleInput(event) {
			if (this.input) {
				this.input(event);
			}
		},
		handleClose() {
			if (this.close) {
				this.close();
			}
		},
		handleDeselect(event) {
			if (this.deselect) {
				this.deselect(event);
			}
		},
	},
};
</script>


