import { BASE_URL } from "@/constants/config";
import { request } from "@/utils/request";

export const ORGS_ENDPOINTS = {
  list: `${BASE_URL}api/organizations/list`,
  details: `${BASE_URL}api/organizations/details`,
  project_details: `${BASE_URL}api/organizations/project_details`,
  create_member: `${BASE_URL}api/organizations/create_member`,
  bulk_members_template: `${BASE_URL}api/organizations/bulk_members_template`,
  bulk_create_members: `${BASE_URL}api/organizations/bulk_create_members`,
  bulk_levels_template: `${BASE_URL}api/organizations/bulk_levels_template`,
  bulk_create_levels: `${BASE_URL}api/organizations/bulk_create_levels`,
  delete: `${BASE_URL}api/organizations/delete`,
  edit_name: `${BASE_URL}api/organizations/edit_name`,
  create: `${BASE_URL}api/organizations/create`,
  get_bubbles_data: `${BASE_URL}api/organizations/get_bubbles_data`,
  list_org_members: `${BASE_URL}api/organizations/list_org_members`,
  get_ribbon_data: `${BASE_URL}api/organizations/get_ribbon_data`,
  delete_member: `${BASE_URL}api/organizations/delete_member`,
  edit_member: `${BASE_URL}api/organizations/edit_member`,
  select_organization: `${BASE_URL}api/organizations/select_organization`,
  select_project: `${BASE_URL}api/organizations/select_project`,
  list_organizations_and_projects: `${BASE_URL}api/organizations/list_organizations_and_projects`,
  get_all_orgs: `${BASE_URL}api/organizations/get_all_orgs`,
  add_level: `${BASE_URL}api/organizations/add_level`,
  delete_level: `${BASE_URL}api/organizations/delete_level`,
  rename_level: `${BASE_URL}api/organizations/rename_level`,
  add_project: `${BASE_URL}api/organizations/add_project`,
  add_project_level: `${BASE_URL}api/organizations/add_project_level`,
  delete_project_level: `${BASE_URL}api/organizations/delete_project_level`,
  rename_project_level: `${BASE_URL}api/organizations/rename_project_level`,
  delete_project: `${BASE_URL}api/organizations/delete_project`,
  edit_project: `${BASE_URL}api/organizations/edit_project`,
  editRoleSystem: (organizationId) =>
    `${BASE_URL}api/organizations/edit_role_system/${organizationId}`,
  organization_levels: `${BASE_URL}api/organizations/details/export`,
  organization_members: `${BASE_URL}api/organizations/export_org_members`,
  change_job_desc: `${BASE_URL}api/organizations/change_job_desc`,
  add_new_role: `${BASE_URL}api/organizations/add_new_role`,
  get_filters: `${BASE_URL}api/organizations/filters`,
};

class Organizations {
  async add_new_role({ role_name, project_id }) {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.add_new_role,
      method: "PUT",
      skipRedirect: true,
      body: { role_name, project_id },
    });
    return {
      data,
      res,
    };
  }
  async edit_project({ organization, old_project_name, new_project_name }) {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.edit_project,
      method: "POST",
      skipRedirect: true,
      body: { organization, old_project_name, new_project_name },
    });
    return {
      data,
      res,
    };
  }

  async delete_project({ organization, project }) {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.delete_project,
      method: "DELETE",
      skipRedirect: true,
      body: { organization, project },
    });
    return {
      data,
      res,
    };
  }

  async add_project_level({ org_name, project_name, parent_id, level_name }) {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.add_project_level,
      method: "POST",
      skipRedirect: true,
      body: { org_name, project_name, parent_id, level_name },
    });
    return {
      data,
      res,
    };
  }

  async delete_project_level({ org_name, project_name, level_id, org_id }) {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.delete_project_level,
      method: "DELETE",
      skipRedirect: true,
      body: { org_name, project_name, level_id, org_id },
    });
    return {
      data,
      res,
    };
  }

  async rename_project_level({ org_name, project_name, level_id, new_name }) {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.rename_project_level,
      method: "PATCH",
      skipRedirect: true,
      body: { org_name, project_name, level_id, new_name },
    });
    return {
      data,
      res,
    };
  }

  async add_project({ organization, project }) {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.add_project,
      method: "PUT",
      skipRedirect: true,
      body: { organization, project },
    });
    return {
      data,
      res,
    };
  }

  async change_job_desc({ job_description, level, role }) {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.change_job_desc,
      method: "PUT",
      skipRedirect: true,
      body: { job_description, level, role },
    });
    return {
      data,
      res,
    };
  }

  async add_level({ org_name, project_name, level_name, parent_id, org_id }) {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.add_level,
      method: "POST",
      skipRedirect: true,
      body: { org_name, project_name, level_name, parent_id, org_id },
    });
    return {
      data,
      res,
    };
  }

  async delete_level({ org_name, prj_name, level_id, orgObj }) {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.delete_level,
      method: "DELETE",
      skipRedirect: true,
      body: { org_name, prj_name, level_id, orgObj },
    });
    return {
      data,
      res,
    };
  }

  async rename_level({ org_name, prj_name, level_id, new_name, org_id }) {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.rename_level,
      method: "PATCH",
      skipRedirect: true,
      body: { org_name, prj_name, level_id, new_name, org_id },
    });
    return {
      data,
      res,
    };
  }

  async get_all_orgs() {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.get_all_orgs,
      method: "GET",
      skipRedirect: true,
    });
    return {
      data,
      res,
    };
  }

  async list_organizations_and_projects(page = "") {
    var { data, res } = await request({
      url: `${ORGS_ENDPOINTS.list_organizations_and_projects}?page=${page}`,
      method: "GET",
      skipRedirect: true,
    });
    return {
      data,
      res,
    };
  }

  async select_organization({ organization, email }) {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.select_organization,
      method: "PUT",
      skipRedirect: true,
      body: { organization, email },
    });
    return {
      data,
      res,
    };
  }

  async select_project({ project, email }) {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.select_project,
      method: "PUT",
      skipRedirect: true,
      body: { project, email },
    });
    return {
      data,
      res,
    };
  }

  async list_org_members({ organization_name, project_name }, page = "") {
    var { data, res } = await request({
      url: `${ORGS_ENDPOINTS.list_org_members}?page=${page}`,
      method: "POST",
      skipRedirect: true,
      body: { organization_name, project_name },
    });
    return {
      data,
      res,
    };
  }

  async delete_member(emails) {
    console.log("emails", emails);
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.delete_member,
      method: "POST",
      skipRedirect: true,
      body: emails,
    });
    return {
      data,
      res,
    };
  }

  async edit_member({ organization, project, email, user_data }) {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.edit_member,
      method: "POST",
      skipRedirect: true,
      body: { organization, project, email, user_data },
    });
    return {
      data,
      res,
    };
  }

  async get_ribbon_data({ organization_name }) {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.get_ribbon_data,
      method: "POST",
      skipRedirect: true,
      body: { organization_name },
    });
    return {
      data,
      res,
    };
  }

  async get_bubbles_data({ organization_name }) {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.get_bubbles_data,
      method: "POST",
      skipRedirect: true,
      body: { organization_name },
    });
    return {
      data,
      res,
    };
  }

  async bulk_create_members({ organization, project, file_name }) {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.bulk_create_members,
      method: "PUT",
      skipRedirect: true,
      body: { organization, project, file_name },
    });
    return {
      data,
      res,
    };
  }

  async bulk_create_levels({ org_name, project_name, file_name }) {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.bulk_create_levels,
      method: "PUT",
      skipRedirect: true,
      body: { org_name, project_name, file_name },
    });
    return {
      data,
      res,
    };
  }

  async list() {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.list,
      method: "GET",
      skipRedirect: true,
    });
    return {
      data,
      res,
    };
  }

  async create({ name, project }) {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.create,
      method: "PUT",
      skipRedirect: true,
      body: { name, project },
    });
    return {
      data,
      res,
    };
  }

  async delete({ name, project, org_id, prj_id }) {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.delete,
      method: "DELETE",
      skipRedirect: true,
      body: { name, project, org_id, prj_id },
    });
    return {
      data,
      res,
    };
  }

  async edit_name({
    org_name,
    project_name,
    new_org_name,
    new_project_name,
    org_id,
    prj_id,
  }) {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.edit_name,
      method: "PUT",
      skipRedirect: true,
      body: {
        org_name,
        project_name,
        new_org_name,
        new_project_name,
        org_id,
        prj_id,
      },
    });
    return {
      data,
      res,
    };
  }

  async details({ name, project, filters }) {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.details,
      method: "POST",
      skipRedirect: true,
      body: { name, project, filters },
    });
    return {
      data,
      res,
    };
  }

  async project_details({ organization, project }) {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.project_details,
      method: "POST",
      skipRedirect: true,
      body: { organization, project },
    });
    return {
      data,
      res,
    };
  }

  async create_member({ organization, project, member_details }) {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.create_member,
      method: "PUT",
      skipRedirect: true,
      body: { organization, project, member_details },
    });
    return {
      data,
      res,
    };
  }

  async bulk_levels_template({ org_name, project_name }) {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.bulk_levels_template,
      method: "POST",
      skipRedirect: true,
      body: { org_name, project_name },
    });

    // Check if the response status is OK (200)
    if (res.ok) {
      const blob = await res.blob();

      // Create a temporary anchor element to trigger the download
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = "Add_levels_template.xlsx"; // Specify the desired file name

      // Programmatically click the anchor element to trigger the download
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();

      // Remove the anchor element
      document.body.removeChild(a);
    } else {
      console.error("Failed to download the file");
    }
  }
  catch(error) {
    console.error("Error downloading the file:", error);
  }

  async bulk_members_template({ organization, project }) {
    var { data, res } = await request({
      url: ORGS_ENDPOINTS.bulk_members_template,
      method: "POST",
      skipRedirect: true,
      body: { organization, project },
    });

    // Check if the response status is OK (200)
    if (res.ok) {
      const blob = await res.blob();

      // Create a temporary anchor element to trigger the download
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = "Add_members_template.xlsx"; // Specify the desired file name

      // Programmatically click the anchor element to trigger the download
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();

      // Remove the anchor element
      document.body.removeChild(a);
    } else {
      console.error("Failed to download the file");
    }
  }
  catch(error) {
    console.error("Error downloading the file:", error);
  }
  async editRoleSystem({ organization_id, role_data }) {
    const { data } = await request({
      url: ORGS_ENDPOINTS.editRoleSystem(organization_id),
      method: "POST",
      body: { data: role_data },
    });
    return data;
  }

  async exportOrgLevels({ name, project }) {
    const { data, res } = await request({
      url: ORGS_ENDPOINTS.organization_levels,
      method: "POST",
      skipRedirect: true,
      body: { name, project },
    });
    // Check if the response status is OK (200)
    if (res.ok) {
      const blob = await res.blob();

      // Create a temporary anchor element to trigger the download
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = "Organization_Levels.xlsx"; // Specify the desired file name

      // Programmatically click the anchor element to trigger the download
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();

      // Remove the anchor element
      document.body.removeChild(a);
    } else {
      console.error("Failed to download the file");
    }
  }
  catch(error) {
    console.error("Error downloading the file:", error);
  }
  async exportOrgMembers({ organization_name, project_name }) {
    const { data, res } = await request({
      url: ORGS_ENDPOINTS.organization_members,
      method: "POST",
      skipRedirect: true,
      body: { organization_name, project_name },
    });
    // Check if the response status is OK (200)
    if (res.ok) {
      const blob = await res.blob();

      // Create a temporary anchor element to trigger the download
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = "Organization_Members.xlsx"; // Specify the desired file name

      // Programmatically click the anchor element to trigger the download
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();

      // Remove the anchor element
      document.body.removeChild(a);
    } else {
      console.error("Failed to download the file");
    }
  }
  catch(error) {
    console.error("Error downloading the file:", error);
  }

  async getFilters() {
    const { data, res } = await request({
      url: ORGS_ENDPOINTS.get_filters,
      method: "POST",
      body: { organization_name: "", project_name: "" },
    });
    return { data, res };
  }
}

export const OrganizationsService = new Organizations();
