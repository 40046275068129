import { request } from "@/utils/request";
import { BASE_URL } from "@/constants/config";

export const SUPPORT_ENDPOINTS = {
    sendMessage: `${BASE_URL}api/support/`,
    sendImage: `${BASE_URL}api/support/upload/`,
};

class Support {
    async sendMessage(message) {
        const { data, res } = await request({
            url: SUPPORT_ENDPOINTS.sendMessage,
            method: "POST",
            body: message,
        });
        return {
            data,
            res,
        };
    }

    async sendImage(form) {
        const { data, res } = await request({
            url: SUPPORT_ENDPOINTS.sendImage,
            method: "POST",
            formData: form,
        });
        return {
            data,
            res,
        };
    }
}

export const SupportService = new Support();
