<template>
  <div>
    <div>
      <div
        :style="{
          cursor: 'pointer',
          backgroundColor: 'inherit',
          //marginLeft: fontSize.marginLeft,
          marginTop: '20px',
        }"
      >
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex gap-2 align-items-center" @click="toggleLevel()">
            <i
              v-if="hasChildren"
              :class="isExpanded ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"
              style="cursor: pointer"
            ></i>

            <span
              :style="calculateStyle()"
              :class="{
                'bold-text': isBold(),
                'regular-text': !isBold(),
              }"
            >
              {{ node.name }}
            </span>
            <div>
              <span style="white-space: nowrap" v-if="node.actions != 0">
                <span style="font-weight: bold; font-size: 15px; margin: 0 10px"
                  >|</span
                >

                {{ node.closed + " / " + node.actions }}</span
              >
            </div>
          </div>

          <div v-if="node.actions != 0" style="margin-left: 10px">
            <button class="details-btn" @click="showDetailsModal(node)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-eye"
                viewBox="0 0 16 16"
              >
                <path
                  stroke-width="10"
                  d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"
                />
                <path
                  stroke-width="10"
                  d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"
                />
              </svg>
            </button>
          </div>
        </div>
        <div class="text-start" v-if="node.actions != 0">
          <span style="">{{ node.progress }} %</span>
          <CProgress style="height: 5px; width: 150px">
            <CProgressBar
              :style="{ backgroundColor: getFillColor(node.progress) }"
              :value="node.progress"
            ></CProgressBar>
          </CProgress>
        </div>
      </div>
    </div>
    <div v-if="hasChildren && (isExpanded || expandedLevels.has(node.id))">
      <ReportingTree
        v-for="child in node.children"
        :key="child.id"
        :node="child"
        :depth="depth + 1"
        :data="data"
        :expandedLevels="expandedLevels"
        :allLevels="allLevels"
        @show-details-modal="$emit('show-details-modal', $event)"
      />
    </div>
  </div>
</template>

<script>
import OrganizationViewTree from "../../organization/components/OrganizationViewTree.vue";

export default {
  name: "ReportingTree",
  components: { OrganizationViewTree },
  props: {
    node: Object,
    depth: Number,
    data: Array,
    expandAll: Boolean,
    expandedLevels: Set,
    allLevels: Set,
  },
  data() {
    return {};
  },
  computed: {
    isExpanded() {
      return this.expandedLevels.has(this.node.name);
    },
    hasChildren() {
      return this.node.children && this.node.children.length > 0;
    },
    fontSize() {
      const baseSize = 16;
      const decreaseFactor = 1;
      const minSize = 8;
      const baseMargin = 20;
      const maxDepth = 5;

      let calculatedFontSize = baseSize - this.depth * decreaseFactor;
      calculatedFontSize = Math.max(calculatedFontSize, minSize);

      let marginLeft = this.depth * baseMargin;
      if (this.depth > maxDepth) {
        marginLeft = maxDepth * baseMargin;
      }
      const boldness = 100 - this.depth * 10;

      return {
        fontSize: calculatedFontSize + "px",
        marginLeft: marginLeft + "px",
        fontWeight: boldness,
      };
    },
  },
  methods: {
    getFillColor(share) {
      if (share <= 24) {
        return "#dc3545";
      } else if (share <= 49) {
        return "#ffc107";
      } else if (share <= 74) {
        return "#33b5e7";
      } else if (share <= 99) {
        return "#0d47a1";
      } else if (share <= 100) {
        return "#28a745";
      }
    },
    showDetailsModal(node) {
      console.log("node", node);
      this.$emit("show-details-modal", node);
    },
    toggleLevel() {
      this.expandedLevels.has(this.node.name)
        ? this.expandedLevels.delete(this.node.name)
        : this.expandedLevels.add(this.node.name);
    },
    calculateStyle() {
      const baseBoldness = 700; // Adjust as needed
      const boldness = baseBoldness - this.depth * 100; // Adjust as needed

      return {
        fontSize: this.fontSize.fontSize,
        fontWeight: boldness,
        // marginLeft: this.fontSize.marginLeft,
      };
    },
    isBold() {
      // Add conditions based on your logic to determine when the text should be bold
      return this.depth < 3;
    },
  },
};
</script>

<style scoped>
.details-btn {
  /* border-radius: 0px 0px 0px 25px; */
  cursor: pointer;
  color: #0070ce;
  background-color: #e5e4e200;
  /* //color: #fff; */
  /* //background-color: #0071ce; */
  /*  border-color: #e5e4e2; */
  border: none;
  padding: 4px 12px;
  /* //border-radius: 0.25em */
}

.details-btn:focus,
.details-btn:active {
  border: none;
  outline: none;
}
.details-btn:hover {
  color: #0070ce;
  transform: scale(1.3);
}
</style>
