import "core-js/stable";
import "regenerator-runtime/runtime";

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

require("moment/locale/fr");
import { createApp } from "vue";
import App from "./App";
import router from "./router";
import CoreuiVue from "@mz/coreui-vue";
import { createI18n } from "vue-i18n";
import store from "./store";

import { useGlobalStore } from "./stores/store";
import { useUserStore } from "./stores/UserStore";
import VueSimpleAlert from "vue3-simple-alert";
import MzRd from "mz-rd";
import { UsersService } from "@/services/users";
import { OrganizationsService } from "@/services/organizations";

// import Vue3Toasity from 'vue3-toastify';
// import 'vue3-toastify/dist/index.css';

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

import { BASE_URL, SENTRY_ENV } from "@/constants/config";
import { HelpersMixin } from "@/mixins/helpers";
import { messages } from "./constants/translations";
import Message from "vue-m-message";
import "vue-m-message/dist/style.css";
import { createPinia } from "pinia";

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(ElementPlus);
app.use(CoreuiVue);
app.use(MzRd, {
  apiUrl: BASE_URL,
  tokenBearer: "Bearer",
  tableClassess: "mz-table",
  isRaTable: true,
});

const defaultStore = useGlobalStore();
const userStore = useUserStore();
// app.use(Vue3Toasity, {
//   autoClose: 3000,
//   theme : 'light'
// })

// app.use(SnackbarService);
// app.component("vue3-snackbar", Vue3Snackbar);
app.use(Message);
Message.setDefault({
  duration: 5000,
  position: "bottom-left",
  zIndex: 100000000,
});
export const i18n = createI18n({
  locale: navigator.language.split("-")[0],
  fallbackLocale: "en",
  messages: messages,
  silentFallbackWarn: true,
  silentTranslationWarn: true,
});

app.mixin(HelpersMixin);

window.alert = (message, additional = {}) => {
  store.commit("setAlert", {
    message,
    color: additional.color || "success",
  });
};

const btnClasses = `btn py-1 px-3`;
const alertConfig = {
  buttonsStyling: false,
  reverseButtons: true,
  width: "24em",
  confirmButtonText: i18n.global.t("Confirm"),
  denyButtonText: i18n.global.t("No"),
  cancelButtonText: i18n.global.t("Cancel"),
  customClass: {
    actions: "justify-content-between",
    denyButton: `${btnClasses} btn-outline-danger`,
    cancelButton: `${btnClasses} dg-btn--cancel btn-outline-danger ml-2`,
    confirmButton: `${btnClasses} dg-btn--ok btn-outline-primary`,
  },
};
app.use(VueSimpleAlert, alertConfig);

window.confirm = async (message, config = {}) => {
  try {
    config = {
      ...config,
      customClass:
        config.showCancelButton === false
          ? { ...alertConfig.customClass, cancelButton: "d-none" }
          : alertConfig.customClass,
    };
    await VueSimpleAlert.confirm(message, "", "", config);
    return true;
  } catch {
    return false;
  }
};

app.config.errorHandler = (error, vm, info) => {
  console.error(error, vm, info);
};

if (process.env.VUE_APP_SENTRY_DSN && SENTRY_ENV !== "DEV")
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [BASE_URL],
      }),
    ],
    environment: SENTRY_ENV,
    tracesSampleRate: 1.0,
  });

(async () => {
  try {
    const userData = await UsersService.getActiveUserInfo();
    if (userData && userData.success) {
      const currentOrg = userData.data.current_organization;
      const currentPrj = currentOrg.projects[0];
      const includedOrgs =
        await OrganizationsService.list_organizations_and_projects();

      function collectLevelObjects(node, levels = []) {
        if (node && Array.isArray(node)) {
          node.forEach((child) => {
            if (child.level_name && child.id) {
              levels.push({ value: child.ob_id, label: child.level_name });
            }
            if (child.children && Array.isArray(child.children)) {
              collectLevelObjects(child.children, levels);
            }
          });
        }
        return levels;
      }
      function findSpecificOrgAndProject(orgs, orgId, projectId) {
        const targetOrg = orgs.find((org) => org._id === orgId);
        if (!targetOrg) {
          console.error("Organization not found!");
          return null;
        }
        const targetProject = targetOrg.projects.find(
          (project) => project._id === projectId
        );
        if (!targetProject) {
          console.error("Project not found!");
          return null;
        }
        return { org: targetOrg, project: targetProject };
      }
      const { org, project } = findSpecificOrgAndProject(
        includedOrgs.data.data,
        currentOrg._id,
        currentPrj._id
      );
      const allOrgLevels = collectLevelObjects(org.tree);
      const allProjectLevels = collectLevelObjects(project.tree);
      const allLevelObjects = allOrgLevels.concat(allProjectLevels);
      defaultStore.setScopeLevelsOptions(allLevelObjects);
      function extractRoleKeys(roleSystem) {
        const roleKeys = [];
        if (roleSystem.front_office) {
          roleKeys.push(...Object.keys(roleSystem.front_office));
        }
        if (roleSystem.back_office) {
          roleKeys.push(...Object.keys(roleSystem.back_office));
        }
        return roleKeys;
      }

      function getRolesForProject(project) {
        const rolesArray = [];
        if (project.roles) {
          project.roles.forEach((role) => {
            rolesArray.push({
              value: role.name,
              label: role.name,
            });
          });
        }
        return rolesArray;
      }

      const rolesArray = getRolesForProject(project);
      defaultStore.setScopeRolesOptions(rolesArray);
      const permissions = await UsersService.getPermissions();

      if (userData.data) {
        userStore.setBlobToken(userData.data.blob_token);
        if (userData.data.current_role) {
          userStore.setCurrentPermissions(userData.data.current_role);
        }
        store.commit("setUserData", userData.data);
        store.commit("setOrg", {
          ...userData.data.current_organization,
          tree_2: includedOrgs.data.data.find(
            (el) => el._id == userData.data.current_organization._id
          ).tree,
        });
        store.commit("setOrgObj", userData.data.current_organization);
        store.commit(
          "setProject",
          userData.data.current_organization.projects[0]
        );
        store.commit("setJobsPoints", userData.data.level_points_v2);
        const firstEntry = Object.entries(
          userData.data.current_job_description
        )[0];

        let currentLevel = userData.data.current_level;
        if (currentLevel === "") {
          let currentRole = Object.keys(
            userData.data.current_job_description
          )[0];
          currentLevel = userData.data.level_points_v2.map((level) => {
            if (level.job === currentRole) {
              return level.level;
            }
          })[0];
        }
        store.commit("setJob", firstEntry);

        userStore.setCurrentLevel(currentLevel);

        userStore.setUserPoints(userData.data.total_points);
        userStore.setUserTrainingPoints(userData.data.training_total_points);
        userStore.setUserTrainingProgress(userData.data.training_points);
        userStore.setTeamTrainingPoints(userData.data.training_team_points);

        const teamPointsData = userData.data.level_points_v2;
        const neededPointsData = teamPointsData.filter((point) => {
          return point.job === firstEntry[0] && point.level === currentLevel;
        })[0];
        userStore.setTeamPoints(neededPointsData);

        userStore.setUsersProgress(userData.data.total_progress);
        userStore.setAllRolePoints(userData.data.all_roles_points);
        const teamProgressData = userData.data.level_progress_v2;
        const neededProgressData = teamProgressData.filter((point) => {
          return point.job === firstEntry[0] && point.level === currentLevel;
        })[0];

        userStore.setUsersTeamProgress(neededProgressData); // team progress in action plan
      }

      if (includedOrgs.data) store.commit("setIncludedOrgs", includedOrgs.data);
      if (permissions.data) {
        store.commit("setIsAdmin", permissions.data.is_admin);
        store.commit("setIsUserAdmin", permissions.data.is_admin);
        userStore.setIsAdminUser(permissions.data.is_admin_member);
        if (!permissions.data.is_admin_member) {
          const firstEntry = Object.entries(
            userData.data.current_job_description
          )[0];

          store.commit(
            "setPermissions",
            permissions.data.level_permissions_roles[firstEntry[0]]
          );
        } else {
          store.commit(
            "setPermissions",
            permissions.data.aggregated_permissions
          );
        }
      }
    }
    app.use(store);
    app.use(router);
    app.use(i18n);
    app.mount("#app");
    app.config.globalProperties.$defaultStore = defaultStore;
  } catch (error) {
    console.error("Failed to initialize user data", error);
  }
})();
