import { h, resolveComponent } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { ActiveUser } from "@/services/user";
import { IS_MZ_BRANDING } from "@/constants/config";
import Reporting from "./views/reporting/Reporting.vue";
import store from "./store.js";

// Containers
const TheContainer = () => import("@/containers/TheContainer");
const AccountContainer = () => import("@/containers/AccountContainer");

// Views
const Dashboard = () => import("@/views/Dashboard");
const Home = () => import("@/views/home/Home");
const Profile = () => import("@/views/profile/Profile");
const ChallengesInfo = () => import("@/views/challenges/ChallengesInfo");
const Challenges = () => import("@/views/challenges/Challenges");
const ActionPlan = () => import("@/views/actionsplan/ActionPlan");
const Organization = () => import("@/views/organization/Organization");
const Agenda = () => import("@/views/agenda/Agenda");
const Communication = () => import("@/views/communication/Communication");
const NewsDetails = () => import("@/views/communication/NewsDetails");
const NewsView = () => import("@/views/communication/NewsView");
const Training = () => import("@/views/trainings/Training");
const TrainingUser = () => import("@/views/trainings/Training(User)");
const RoleManagement = () => import("@/views/rolemanagement/RoleManagement");
const Guide = () => import("@/views/guide/Guide");

// Views - Integration
const Files = () => import("@/views/integration/Files");
const Tasks = () => import("@/views/integration/Tasks");
const LazyTable = () => import("@/views/integration/LazyTable");
const SampleCharts = () => import("@/views/integration/SampleCharts");
const News = () => import("@/views/integration/News");

// Views - Pages
const Page404 = () => import("@/views/auth/Page404");
const Login = () => import("@/views/auth/Login");
const ForgotPassword = () => import("@/views/auth/ForgotPassword");
const ResetPassword = () => import("@/views/auth/ResetPassword");
const SetupPassword = () => import("@/views/auth/SetupPassword");

// Users
const Users = () => import("@/views/users/UsersView");
const User = () => import("@/views/users/UserView");

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes: configRoutes(),
});

const isOpenAccess = (route) =>
  route.matched.some((route) => route.meta?.isOpenAccess);

const isFound = (route) => route.matched[0].name !== "NotFound";

router.beforeEach((to, from, next) => {
  if (to.meta?.getTitle) to.meta.title = to.meta.getTitle(to);
  const isAuthenticated = ActiveUser.get();
  store.commit("setLoginStatus", isAuthenticated);
  if (!isAuthenticated && !isOpenAccess(to)) {
    if (isFound(to)) {
      localStorage.setItem("pathToLoadAfterLogin", to.path);
    }
    return next({ name: IS_MZ_BRANDING ? "Login" : "Management Login" });
  }
  return next();
});
export default router;

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/home",
      component: TheContainer,
      children: [
        {
          path: "home",
          name: "Home",
          component: Home,
        },
        {
          path: "integration",
          name: "Integration",
          component: {
            render() {
              return h(resolveComponent("router-view"));
            },
          },
          children: [
            {
              path: "lazytable",
              name: "Lazy table",
              component: LazyTable,
            },
            {
              path: "files",
              name: "Files",
              component: Files,
            },
            {
              path: "tasks",
              name: "Tasks",
              component: Tasks,
            },
            {
              path: "charts",
              name: "Charts",
              component: SampleCharts,
            },
            // {
            //   path: "news",
            //   name: "News",
            //   component: News,
            // },
          ],
        },
        {
          path: "users",
          meta: {
            label: "Users",
          },
          component: {
            render() {
              return h(resolveComponent("router-view"));
            },
          },
          children: [
            {
              path: "",
              name: "Users",
              component: Users,
            },
            {
              path: ":id",
              meta: {
                label: "User Details",
              },
              name: "User",
              component: User,
            },
          ],
        },
        {
          path: "/profile",
          name: "Profile",
          component: Profile,
        },
        {
          path: "/challenges",
          name: "Challenges",
          component: Challenges,
        },
        {
          path: "/guide",
          name: "Guide",
          component: Guide,
        },
        {
          path: "/action-plan",
          name: "Action Plan",
          component: ActionPlan,
        },
        {
          path: "/organization",
          name: "Organization",
          component: Organization,
        },
        {
          path: "/agenda",
          name: "Agenda",
          component: Agenda,
        },
        {
          path: "/communication",
          name: "Communication",
          component: Communication,
        },
        {
          path: "/challenges-info",
          name: "Challenges Info",
          component: ChallengesInfo,
        },
        {
          path: "/news",
          name: "News",
          component: NewsView,
        },
        {
          path: "/news/:id",
          name: "News Details",
          component: NewsDetails,
        },
        {
          path: "/training",
          name: "TrainingUser",
          component: TrainingUser,
        },
        {
          path: "admin/training",
          name: "Training",
          component: Training,
        },
        {
          path: "/reporting",
          name: "Reporting",
          component: Reporting,
        },
        {
          path: "/role-management",
          name: "Role Management",
          component: RoleManagement,
        },
      ],
    },
    {
      path: "/oauth",
      meta: {
        isOpenAccess: true,
      },
      component: AccountContainer,
      children: [
        {
          path: "",
          name: "OAuth Login",
          component: Login,
        },
      ],
    },
    {
      path: "/account",
      name: "account",
      meta: {
        isOpenAccess: true,
      },
      component: AccountContainer,
      children: [
        {
          path: "login",
          name: "Login",
          component: Login,
        },
        {
          path: "forgot",
          name: "Forgot password",
          component: ForgotPassword,
        },
        {
          path: "reset/:token",
          name: "Password reset",
          component: ResetPassword,
        },
        {
          path: "login_management",
          name: "Management Login",
          component: Login,
          props: {
            show_email_login: true,
          },
        },
        {
          path: "setup/:token",
          name: "Setup Password",
          component: SetupPassword,
        },
      ],
    },
    {
      path: "/pages",
      redirect: "/pages/404",
      name: "Pages",
      component: {
        render() {
          return h(resolveComponent("router-view"));
        },
      },
      children: [
        {
          path: "404",
          name: "Page404",
          component: Page404,
        },
      ],
    },
    {
      path: "/:pathMatch(.*)*",
      component: TheContainer,
      children: [
        {
          path: "",
          name: "NotFound",
          component: Page404,
        },
      ],
    },
  ];
}
