import { BASE_URL } from "@/constants/config";
import { request } from "@/utils/request";

export const ACTIONS_ENDPOINTS = {
  create_action: `${BASE_URL}api/actions/create_action`,
  bulk_actions_template: `${BASE_URL}api/actions/bulk_actions_template`,
  bulk_create_actions: `${BASE_URL}api/actions/bulk_create_actions`,
  edit_action: `${BASE_URL}api/actions/edit_action`,
  delete_action: `${BASE_URL}api/actions/delete_action`,
  delete_actions: `${BASE_URL}api/actions/delete_actions`,
  list_actions: `${BASE_URL}api/actions/list_actions`,
  get_action_plan: `${BASE_URL}api/actions/get_action_plan`,
  update_advancement: `${BASE_URL}api/actions/update_advancement`,
  list_actions_export: `${BASE_URL}api/actions/list_actions/export`,
};

class Actions {
  async update_advancement({
    organization,
    phase,
    category,
    action,
    project,
    email,
    advancement,
    job_description,
    level_name,
    _id,
  }) {
    var { data, res } = await request({
      url: ACTIONS_ENDPOINTS.update_advancement,
      method: "PUT",
      skipRedirect: true,
      body: {
        organization,
        phase,
        category,
        action,
        project,
        email,
        advancement,
        job_description,
        level_name,
        _id,
      },
    });
    return {
      data,
      res,
    };
  }

  async list_actions({ organization, project, role, user, view }) {
    var { data, res } = await request({
      url: ACTIONS_ENDPOINTS.list_actions,
      method: "POST",
      skipRedirect: true,
      body: { organization, project, role, user, view },
    });
    return {
      data,
      res,
    };
  }

  async delete_action({
    action_id,
    user_email,
    job_description,
    level_name,
    _id,
  }) {
    var { data, res } = await request({
      url: ACTIONS_ENDPOINTS.delete_action,
      method: "DELETE",
      skipRedirect: true,
      body: { action_id, user_email, job_description, level_name, _id },
    });
    return {
      data,
      res,
    };
  }
  async delete_actions(body) {
    var { data, res } = await request({
      url: ACTIONS_ENDPOINTS.delete_actions,
      method: "DELETE",
      skipRedirect: true,
      body: body,
    });
    return {
      data,
      res,
    };
  }

  async edit_action({ type, action_id, action_data, _id }) {
    var { data, res } = await request({
      url: ACTIONS_ENDPOINTS.edit_action,
      method: "POST",
      skipRedirect: true,
      body: { type, action_id, action_data, _id },
    });
    return {
      data,
      res,
    };
  }

  async bulk_create_actions({ file_name, organization_name, project_name }) {
    var { data, res } = await request({
      url: ACTIONS_ENDPOINTS.bulk_create_actions,
      method: "PUT",
      skipRedirect: true,
      body: { file_name, organization_name, project_name },
    });
    return {
      data,
      res,
    };
  }

  async create_action(actionData) {
    var { data, res } = await request({
      url: ACTIONS_ENDPOINTS.create_action,
      method: "PUT",
      skipRedirect: true,
      body: actionData,
    });
    return {
      data,
      res,
    };
  }

  async bulk_actions_template({ organization, project }) {
    // const response = await fetch(ACTIONS_ENDPOINTS.bulk_actions_template);
    var { data, res } = await request({
      url: ACTIONS_ENDPOINTS.bulk_actions_template,
      method: "POST",
      skipRedirect: true,
      body: { organization, project },
    });
    // Check if the response status is OK (200)
    if (res.ok) {
      const blob = await res.blob();

      // Create a temporary anchor element to trigger the download
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = "Add_actions_template.xlsx"; // Specify the desired file name

      // Programmatically click the anchor element to trigger the download
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();

      // Remove the anchor element
      document.body.removeChild(a);
    } else {
      console.error("Failed to download the file");
    }
  }
  catch(error) {
    console.error("Error downloading the file:", error);
  }

  async get_action_plan({ filters }) {
    var { data, res } = await request({
      url: ACTIONS_ENDPOINTS.get_action_plan,
      method: "POST",
      skipRedirect: true,
      body: { filters },
    });
    return {
      data,
      res,
    };
  }

  async list_actions_export({ organization, project, role, user, view }) {
    const { data, res } = await request({
      url: ACTIONS_ENDPOINTS.list_actions_export,
      method: "POST",
      skipRedirect: true,
      body: { organization, project, role, user, view },
    });

    if (res.ok) {
      const blob = await res.blob();
      const fileName = "Actions_export.xlsx";

      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = fileName;

      a.style.display = "none";
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
    } else {
      console.error("Failed to download the file");
    }
  }
  catch(error) {
    console.error("Error downloading the file", error);
  }
}

export const ActionsService = new Actions();
