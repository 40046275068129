<template>
  <div class="container h-100">
    <ContentLoader
      v-if="isLoading"
      :speed="2"
      primaryColor="rgb(230, 230, 230)"
      secondaryColor="rgb(240, 240, 240)"
      class="p-1"
    ></ContentLoader>
    <div v-else style="height: 100%">
      <div class="bubbleContainer" id="tree-img ">
        <div
          class="d-flex justify-content-between align-items-center"
          style="border-bottom: 1px solid #c3c3c3"
        >
          <div
            class="gap-2 justify-content-between ml-2 mr-2 w-50 desktopLegend"
          >
            <div class="legendColorOptions" v-for="item in legendColors">
              <div
                class="legendColorOption"
                :style="'background-color:' + item.color"
              ></div>
              <span>{{ item.value }}</span>
            </div>
          </div>
          <div class="d-flex gap-2 justify-content-end zoom mb-2 pt-2 mr-2">
            <button
              @click="zoomIn"
              class="py-1 px-2 zoom-btn"
              v-c-tooltip="{
                content: $t('Zoom in'),
                html: true,
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="#747474"
                class="bi bi-zoom-in"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0"
                />
                <path
                  d="M10.344 11.742q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1 6.5 6.5 0 0 1-1.398 1.4z"
                />
                <path
                  fill-rule="evenodd"
                  d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5"
                />
              </svg>
            </button>
            <button
              @click="zoomOut"
              class="py-1 px-2 zoom-btn"
              v-c-tooltip="{
                content: $t('Zoom out'),
                html: true,
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="#747474"
                class="bi bi-zoom-out"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0"
                />
                <path
                  d="M10.344 11.742q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1 6.5 6.5 0 0 1-1.398 1.4z"
                />
                <path
                  fill-rule="evenodd"
                  d="M3 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5"
                />
              </svg>
            </button>
            <button
              @click="restoreScale"
              class="py-1 px-2 zoom-btn"
              v-c-tooltip="{
                content: $t('Reset zoom'),
                html: true,
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="#747474"
                class="bi bi-arrow-clockwise"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
                />
                <path
                  d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"
                />
              </svg>
            </button>
          </div>
        </div>

        <div
          class="gap-2 justify-content-between ml-2 mr-2 pt-2 w-50 mobileLegend"
        >
          <div class="legendColorOptions" v-for="item in legendColors">
            <div
              class="legendColorOption"
              :style="'background-color:' + item.color"
            ></div>
            <span>{{ item.value }}</span>
          </div>
        </div>
        <vue-tree
          ref="treeRef"
          class="bubbleTree"
          :dataset="data"
          :config="treeConfig"
          linkStyle="straight"
        >
          <template
            v-slot:node="{ node, collapsed }"
            v-c-tooltip="{ content: node.name }"
          >
            <div class="hierarchy-chart" :class="{ hasChildren: collapsed }">
              <span
                class="legend"
                :style="{ backgroundColor: getFillColor(node) }"
              ></span>
              <!--              <div style="padding: 4px 0" class="d-flex flex-column justify-content-between">-->
              <div class="node-name">{{ node.name }}</div>
              <div>
                <span>{{ node.closed + " / " + node.actions }}</span>
                |
                <span>
                  {{
                    node.progress % 1 === 0
                      ? node.progress.toFixed(0) + " %"
                      : node.progress.toFixed(2) + " %"
                  }}
                </span>
              </div>

              <!--              </div>-->
            </div>
          </template>
        </vue-tree>
      </div>
    </div>
  </div>
</template>
<script>
import VueTree from "@ssthouse/vue3-tree-chart";
import "@ssthouse/vue3-tree-chart/dist/vue3-tree-chart.css";
import { ContentLoader } from "vue-content-loader";

export default {
  components: { ContentLoader, VueTree },
  props: {
    data: Array,
    isLoading: Boolean,
  },
  data() {
    return {
      filteredData: this.data.filter((node) => node.level === 0),

      legendColors: [
        {
          color: "#dc3545",
          value: "0% - 25%",
        },
        {
          color: "#ffc107",
          value: "25% - 50%",
        },
        {
          color: "#33b5e7",
          value: "50% - 75%",
        },
        {
          color: "#0d47a1",
          value: "75% - 99%",
        },
        {
          color: "#28a745",
          value: "100%",
        },
      ],
      treeConfig: { nodeWidth: 120, nodeHeight: 80, levelHeight: 200 },
    };
  },
  created() {},
  methods: {
    zoomIn() {
      this.$refs.treeRef.zoomIn();
    },
    zoomOut() {
      this.$refs.treeRef.zoomOut();
    },
    restoreScale() {
      this.$refs.treeRef.restoreScale();
    },

    getFillColor(node) {
      if (node.actions > 0) {
        if (node.progress < 25 || node.progress === 25) {
          return "#dc3545"; // red
        } else if (node.progress < 50 || node.progress === 50) {
          return "#ffc107"; // orange
        } else if (node.progress < 75 || node.progress === 75) {
          return "#33b5e7"; // cyan
        } else if (node.progress < 99 || node.progress === 99) {
          return "#0d47a1"; // blue
        } else if (node.progress < 100 || node.progress === 100) {
          return "#28a745"; // green
        }
      }
    },
  },
  watch: {
    data: {
      handler() {},
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  margin: 0;
  max-width: unset;
  padding: 0px;
}
.node-name {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
}
.hierarchy-chart {
  max-width: 110px;
  min-width: 100px;
  height: 100px;
  padding: 2px 0 8px 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  color: #434343;
  border-radius: 6px;
  background-color: #e5e4e2;
  //border-radius: 6px;
  font-size: 12px;
  position: relative;
  overflow: hidden;
}

.hasChildren {
  padding: 9px;
  border: 1px solid #cecece;
}

.bubbleContainer {
  border: 1px solid #c3c3c3;
  height: 100%;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.123) 5px 2px 15px;
}

.legend {
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 5px;
  /* background-color: #ff0000; Replace with the desired color */
  border-radius: 4px 0 0 4px;
}
.hierarchy-chart span {
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide any overflow beyond the container */
  text-overflow: ellipsis; /* Display an ellipsis (...) when text overflows */
}

.zoom {
  width: 100%;
  .zoom-btn {
    //border-radius: 0px 0px 0px 25px;
    cursor: pointer;
    color: #434343;
    background: #ffffff;
    border: 1px solid #c3c3c3;
    /* border-radius: 0.25em */
  }
  .zoom-btn:focus,
  .zoom-btn:active {
    border: none;
    outline: none;
  }
}

.legendColorOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  font-size: small;
  .legendColorOption {
    width: 28px;
    height: 28px;
    margin: 0 5px;
    cursor: pointer;

    &:hover {
      border: 1px solid rgb(185, 185, 185);
    }
  }
}

.bubbleTree {
  border: 0px solid #cecece;
  border-radius: 0px;
  height: calc(100vh - 335px);
}
.mobileLegend {
  display: none;
}
.desktopLegend {
  display: flex;
}
@media (max-width: 815px) {
  .bubbleTree {
    height: 600px;
    overflow: scroll;
  }
}
@media (max-width: 1089px) {
  .mobileLegend {
    display: flex;
  }
  .desktopLegend {
    display: none;
  }
}
@media (max-width: 650px) {
  .mobileLegend {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
