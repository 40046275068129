<template>
  <div ref="orgSelectorContainer" data-testid="organizationSelector">
    <div
      class="orgSelector"
      @click="toggleOptions"
      data-testid="currentOrganization"
      style="z-index: 5"
    >
      <h4 class="mb-0">
        {{ selectedOrg.name || selectedOrg }} / {{ selectedProject.name }}

        <i class="fa fa-chevron-down"></i>
      </h4>
    </div>
    <div v-if="showOptions" class="dropdown-box" data-testid="dropdown-box">
      <div
        v-for="org in orgsToShow"
        :key="org"
        class="orgProjectOption"
        @click="selectOrgProject(org)"
        :style="{
          background:
            selectedOrg.name == (org.name || org.organization) &&
            selectedProject == org.projects[0].name
              ? '#8fb9db'
              : '',
        }"
      >
        <div
          :data-testid="
            (org.name || org.organization) + '/' + org.projects[0].name
          "
        >
          {{ org.name || org.organization }} / {{ org.projects[0].name }}
        </div>
        <div
          v-if="
            (seeingALL && (org.name || org.organization) != 'Admin') ||
            org.projects[0].name != 'Admin'
          "
          style="display: flex; gap: 10px; align-items: center"
        >
          <div
            v-if="userStore.isAdminUser"
            v-c-tooltip="$t('Rename Project')"
            class="actions-icons"
            @click.stop="openEditModal(org, org.projects[0])"
          >
            <i class="blueEditIcon fa fa-pencil" />
          </div>
          <div
            data-testid="deleteProject"
            v-if="userStore.isAdminUser"
            v-c-tooltip="$t('Delete Project')"
            class="actions-icons"
            @click.stop="openDeleteModal(org, org.projects[0])"
          >
            <img
              class="img"
              :src="require('@/assets/Images/cross.png')"
              alt="delete"
            />
          </div>
        </div>
      </div>
      <div v-if="userStore.isAdminUser">
        <div
          style="display: flex; justify-content: center; margin-top: 10px"
          data-testid="create-org-container"
        >
          <CButton
            data-testid="createNewOrg"
            class="create-org-btn"
            color="primary"
            v-if="seeingALL"
            @click="openAddOrgModal()"
          >
            Create new organization
          </CButton>
        </div>
        <!-- <p
          @click="openAddOrgModal()"
          v-if="seeingALL"
          style="
            color: #0071ce;
            font-size: 12px;
            padding: 0;
            margin: 0 0 10px 20px;
            cursor: pointer;
          "
        >
          Create new organization
        </p> -->
        <p
          class="hideshow-all-text"
          v-if="!seeingALL"
          data-testid="seeAllOrgs"
          @click="toggleShownOrgs($event)"
        >
          See All
        </p>
        <p v-else class="hideshow-all-text" @click="toggleShownOrgs($event)">
          Hide All
        </p>
      </div>
    </div>
    <OrganizationEditModal
      :show="showEditOrgModal"
      @close="this.showEditOrgModal = false"
      :orgToEdit="selectedOrgToEdit"
      :projectToEdit="selectedProjectToEdit"
    ></OrganizationEditModal>
    <OrganizationCreateModal
      :show="showAddOrgModal"
      @close="this.showAddOrgModal = false"
    />
    <OrganizationDeleteModal
      :show="showDeleteOrgModal"
      :orgToDelete="selectedOrgToEdit"
      :projectToDelete="selectedProjectToEdit"
      @close="showDeleteOrgModal = false"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import { UsersService } from "@/services/users";
import OrganizationCreateModal from "@/views/organization/organizationSelect/modals/OrganizationCreateModal.vue";
import OrganizationEditModal from "@/views/organization/organizationSelect/modals/OrganizationEditModal.vue";
import OrganizationDeleteModal from "@/views/organization/organizationSelect/modals/OrganizationDeleteModal.vue";
import { useUserStore } from "@/stores/UserStore";

export default {
  name: "OrganizationSelector",
  data() {
    return {
      userStore: useUserStore(),
      showOptions: false,
      orgsToShow: [],
      seeingALL: false,
      showAddOrgModal: false,
      showEditOrgModal: false,
      showDeleteOrgModal: false,
      selectedOrgToEdit: "",
      selectedProjectToEdit: "",
    };
  },
  watch: {
    includedOrgs() {
      if (this.seeingALL) {
        this.orgsToShow = this.includedOrgs.data;
      }
    },
    orgsToShow(newVal) {
      const adminOrgIndex = newVal.findIndex(
        (org) =>
          org.organization === "Admin" &&
          org.projects.some((project) => project.name === "Admin")
      );

      if (adminOrgIndex > 0) {
        const adminOrg = newVal.splice(adminOrgIndex, 1)[0];
        newVal.unshift(adminOrg);
      }

      this.orgsToShow = newVal;
    },
  },
  components: {
    OrganizationCreateModal,
    OrganizationEditModal,
    OrganizationDeleteModal,
  },
  props: {
    parent: String,
  },
  computed: {
    ...mapState({
      userData: (state) => state.userData,
      selectedOrg: (state) => state.selectedOrg,
      selectedProject: (state) => state.selectedProject,
      includedOrgs: (state) => state.includedOrgs,
      isAdmin: (state) => state.isAdmin,
    }),
  },
  methods: {
    openEditModal(org, project) {
      this.showOptions = false;
      this.selectedOrgToEdit = org;
      this.selectedProjectToEdit = project;
      this.showEditOrgModal = true;
    },
    openAddOrgModal() {
      this.showOptions = false;
      this.showAddOrgModal = true;
    },
    openDeleteModal(org, project) {
      this.showOptions = false;
      this.selectedOrgToEdit = org;
      this.selectedProjectToEdit = project;
      this.showDeleteOrgModal = true;
    },
    async selectOrgProject(org) {
      if (org.hasOwnProperty("organization")) {
        org.name = org.organization;
        delete org.organization;
      }

      if (
        org.organization != this.selectedOrg &&
        org.projects[0].name != this.selectedProject
      ) {
        this.showOptions = false;
        await UsersService.setState(org._id);
        const currentUrl = window.location.href;
        window.location.reload();
        window.location.href = currentUrl;
      }
    },

    toggleOptions() {
      this.showOptions = !this.showOptions;
    },
    toggleShownOrgs(event) {
      event.stopPropagation();
      if (!this.seeingALL) {
        this.orgsToShow = this.includedOrgs.data;
        this.seeingALL = true;
      } else {
        this.orgsToShow = this.includedOrgs.data.filter((org) => {
          const key = `${org.organization || org.name} / ${
            org.projects[0].name
          }`;
          return this.userData.organizations.includes(key);
        });
        this.seeingALL = false;
      }
    },
    showNewOrgss() {
      this.orgsToShow = this.includedOrgs.data;
    },
  },
  mounted() {
    this.orgsToShow = this.includedOrgs.data.filter((org) => {
      const key = `${org.organization || org.name} / ${org.projects[0].name}`;
      return this.userData.organizations.includes(key);
    });

    this.clickOutsideListener = (event) => {
      if (!this.$refs.orgSelectorContainer.contains(event.target)) {
        this.showOptions = false;
      }
    };
    document.addEventListener("click", this.clickOutsideListener);
  },

  beforeUnmount() {
    document.removeEventListener("click", this.clickOutsideListener);
  },
};
</script>
<style scoped>
.orgSelector {
  cursor: pointer;
}
.orgProjectOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  /* border-bottom: 1px solid rgb(195, 195, 195); */
}
.orgProjectOption:hover {
  background: #f5f5f5;
}
.orgProjectOption:active {
  background: #e6e6e6;
}
.img {
  width: 11px;
  height: 11px;
  margin-bottom: 1px;
}
.blueEditIcon {
  color: #0071ce;
  font-size: 13px;
}
.dropdown-box {
  max-height: 400px;
  overflow: auto;
  background: white;
  position: absolute;
  border-radius: 5px;
  /* border: 1px solid rgb(195, 195, 195); */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 5 !important;
  width: 500px;
}
@media (max-width: 501px) {
  .dropdown-box {
    width: 100%;
    margin-right: 20px;
  }
  .org-title {
    font-size: 12px;
  }
}
.actions-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(195, 195, 195);
  width: 25px;
  height: 25px;
}
.actions-icons:hover {
  background: rgb(226, 224, 224);
}
.create-org-btn {
  border-radius: 0px 0px 0px 25px;
  margin-bottom: 10px;
}
.hideshow-all-text {
  display: flex;
  justify-content: center;
  color: #0071ce;
  font-size: 12px;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.hideshow-all-text:hover {
  background: rgb(238, 236, 236);
}
</style>
