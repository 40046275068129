<template>
  <div>
    <CModal @hide="close" :show.sync="show" alignment="center" size="xl">
      <template v-slot:header>
        <h3>{{ selectedItem ? selectedItem.name : "" }}</h3>
        <CButton @click="close">
          <i class="fa fa-times"></i>
        </CButton>
      </template>
      <div v-if="filteredPhases" style="max-height: 70vh">
        <div
          v-if="filteredPhases.length === 0"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          "
        >
          <h3>{{ $t("No actions available") }}</h3>
        </div>
        <div
          v-else
          v-for="phase in filteredPhases"
          :key="phase.phase_name"
          style="margin-bottom: 20px"
        >
          <h3>{{ phase.phase_name }}</h3>
          <div
            v-for="category in phase.categories"
            :key="category.category_name"
            style="background: #f1f2f2; padding: 20px"
          >
            <div v-if="filterActionsByLevel(category.actions).length">
              <h4>{{ category.category_name }}</h4>
              <div style="overflow-x: auto">
                <table class="table bg-white">
                  <thead>
                    <tr>
                      <th></th>
                      <th>#</th>
                      <th>{{ $t("Action") }}</th>
                      <th>{{ $t("Description ") }}</th>
                      <th>{{ $t("Deadline") }}</th>
                      <th>{{ $t("Progress") }}</th>
                      <th>{{ $t("Points") }}</th>
                    </tr>
                  </thead>

                  <tbody>
                    <template
                      v-for="action in filterActionsByLevel(category.actions)"
                      :key="action.action_details.id"
                    >
                      <tr>
                        <td>
                          <i
                            :class="{
                              'fa fa-chevron-up': isRowExpanded(
                                phase,
                                category,
                                action
                              ),
                              'fa fa-chevron-down': !isRowExpanded(
                                phase,
                                category,
                                action
                              ),
                            }"
                            @click="toggleRow(phase, category, action)"
                            class="pointer"
                            style="font-size: 20px"
                          ></i>
                        </td>
                        <td>{{ action.action_details.id }}</td>
                        <td>{{ action.action_details.action }}</td>
                        <td>{{ action.action_details.subject }}</td>
                        <td>
                          {{ getFormattedDate(action.action_details.deadline) }}
                        </td>
                        <td>{{ calculateAverageAdvancement(action) }} %</td>
                        <td>{{ calculateSumOfPoints(action) }} pts</td>
                      </tr>
                      <template v-if="isRowExpanded(phase, category, action)">
                        <tr>
                          <th></th>
                          <th></th>

                          <th
                            style="
                              background-color: rgb(157 209 252 / 50%);
                              width: 100%;
                            "
                          >
                            {{ $t("Job description") }}
                          </th>
                          <th
                            style="
                              background-color: rgb(157 209 252 / 50%);
                              width: 100%;
                            "
                          >
                            {{ $t("FirstName") }}
                          </th>
                          <th
                            style="
                              background-color: rgb(157 209 252 / 50%);
                              width: 100%;
                            "
                          >
                            {{ $t("LastName") }}
                          </th>
                          <th
                            style="
                              background-color: rgb(157 209 252 / 50%);
                              width: 100%;
                            "
                          >
                            {{ $t("Progress") }}
                          </th>
                          <th
                            style="
                              background-color: rgb(157 209 252 / 50%);
                              width: 100%;
                            "
                          >
                            {{ $t("Awards") }}
                          </th>
                        </tr>
                        <tr
                          v-for="user in filterUsersByLevel(action.users)"
                          :key="user.email"
                        >
                          <td></td>
                          <td></td>

                          <td
                            style="
                              background-color: rgb(157 209 252 / 50%);
                              width: 100%;
                            "
                          >
                            {{ user.job_description }}
                          </td>
                          <td
                            style="
                              background-color: rgb(157 209 252 / 50%);
                              width: 100%;
                            "
                          >
                            {{ user.firstname }}
                          </td>
                          <td
                            style="
                              background-color: rgb(157 209 252 / 50%);
                              width: 100%;
                            "
                          >
                            {{ user.lastname }}
                          </td>
                          <td
                            style="
                              background-color: rgb(157 209 252 / 50%);
                              width: 100%;
                            "
                          >
                            {{ user.advancement }} %
                          </td>
                          <td
                            style="
                              background-color: rgb(157 209 252 / 50%);
                              width: 100%;
                            "
                          >
                            {{ user.award }} pts
                          </td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <CButton @click="close" class="link">{{ $t("Close") }}</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { ActionsService } from "@/services/actions.js";

export default {
  name: "DetailsModal",
  data() {
    return {
      expandedRows: new Set(),
    };
  },
  props: {
    show: Boolean,
    selectedItem: Object,
    allActions: Array,
  },
  computed: {
    filteredPhases() {
      return this.allActions
        .map((phase) => {
          const filteredCategories = (phase.categories || [])
            .map((category) => {
              const filteredActions = this.filterActionsByLevel(
                category.actions || []
              );
              return { ...category, actions: filteredActions };
            })
            .filter(
              (category) => category.actions && category.actions.length > 0
            )
            .sort((a, b) => a.category_name.localeCompare(b.category_name));

          return { ...phase, categories: filteredCategories };
        })
        .filter((phase) => phase.categories.length > 0)
        .sort((a, b) => a.phase_name.localeCompare(b.phase_name));
    },
  },

  methods: {
    calculateSumOfPoints(item) {
      const filteredUsers = this.filterUsersByLevel(item.users);

      const sumPoints = filteredUsers.reduce((sum, user) => {
        return sum + (user.award || 0);
      }, 0);

      return sumPoints;
    },
    isRowExpanded(phase, category, action) {
      const myphase = phase.phase_name;
      const mycategory = category.category_name;
      const myaction = action.action_details.action;
      const key = `${myphase}-${mycategory}-${myaction}`;
      if (this.expandedRows.has(key)) {
        return true;
      }
    },
    toggleRow(phase, category, action) {
      const myphase = phase.phase_name;
      const mycategory = category.category_name;
      const myaction = action.action_details.action;
      const key = `${myphase}-${mycategory}-${myaction}`;
      if (this.expandedRows.has(key)) {
        this.expandedRows.delete(key);
      } else {
        this.expandedRows.add(key);
      }
    },
    close() {
      this.$emit("close");
    },
    filterActionsByLevel(actions) {
      if (this.selectedItem) {
        return actions.filter((action) =>
          action.users.some(
            (user) => user.level_name === this.selectedItem.name
          )
        );
      }
    },
    getFillColor(share) {
      if (share <= 24) {
        return "danger";
      } else if (share <= 49) {
        return "light";
      } else if (share <= 74) {
        return "warning";
      } else if (share <= 99) {
        return "primary";
      } else if (share <= 100) {
        return "success";
      }
    },
    getFormattedDate(date) {
      const formattedDate = date.split("/").reverse().join("-");

      const parsedDate = new Date(formattedDate);

      const day = parsedDate.getDate().toString().padStart(2, "0");

      const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
      const year = parsedDate.getFullYear();

      return `${day}-${month}-${year}`;
    },
    filterActionsByLevel(actions) {
      if (this.selectedItem) {
        return actions.filter((action) =>
          action.users.some(
            (user) => user.level_name === this.selectedItem.name
          )
        );
      }
    },
    calculateAverageAdvancement(item) {
      const filteredUsers = this.filterUsersByLevel(item.users);

      // Calculate the sum of advancements
      const sumAdvancement = filteredUsers.reduce((sum, user) => {
        return sum + (user.advancement || 0);
      }, 0);

      // Calculate the average advancement
      let averageAdvancement =
        filteredUsers.length > 0 ? sumAdvancement / filteredUsers.length : 0;

      // Check if the average advancement is an integer or a float
      if (averageAdvancement % 1 === 0) {
        // It's an integer, don't show any decimal places
        return averageAdvancement;
      } else {
        // It's a float, format to two decimal places
        return averageAdvancement.toFixed(2);
      }
    },
    filterUsersByLevel(users) {
      if (this.selectedItem) {
        return users.filter(
          (user) => user.level_name === this.selectedItem.name
        );
      }
    },
  },
  watch: {
    show() {},
  },
};
</script>

<style scoped>
::v-deep .modal.show .modal-dialog {
  left: unset !important;
}
.table {
  width: 100%;
  table-layout: fixed;
}
.table th,
.table td {
  border-top: none;
  overflow: hidden;
}
.table th:nth-child(1),
.table td:nth-child(1) {
  width: 30px;
}
.table th:nth-child(2),
.table td:nth-child(2) {
  width: 50px;
}
.table th:nth-child(3),
.table td:nth-child(3) {
  width: 200px;
}
.table th:nth-child(4),
.table td:nth-child(4) {
  width: 200px;
}
.table th:nth-child(5),
.table td:nth-child(5) {
  width: 100px;
}
.table th:nth-child(6),
.table td:nth-child(6) {
  width: 100px;
}
.table th:nth-child(7),
.table td:nth-child(7) {
  width: 70px;
}
</style>
