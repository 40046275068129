import { BASE_URL } from "@/constants/config";
import { request } from "@/utils/request";

export const REPORTING_ENDPOINTS = {
  get_data: `${BASE_URL}api/reporting/get_data`,
  get_phases: `${BASE_URL}api/reporting/get_phases`,
  get_categories: `${BASE_URL}api/reporting/get_categories`,
  get_levels: `${BASE_URL}api/reporting/get_levels`,
  actions_details: `${BASE_URL}api/reporting/actions_details`,
  get_training_data: `${BASE_URL}api/reporting/get_data_training`,
};

class Reporting {
  async get_data({ organization, project, level, phase, category }) {
    var { data, res } = await request({
      url: REPORTING_ENDPOINTS.get_data,
      method: "POST",
      skipRedirect: true,
      body: {
        organization,
        project,
        level,
        phase,
        category,
      },
    });
    return {
      data,
      res,
    };
  }

  async actions_details({ organization, project }) {
    var { data, res } = await request({
      url: REPORTING_ENDPOINTS.actions_details,
      method: "POST",
      skipRedirect: true,
      body: {
        organization,
        project,
      },
    });
    return {
      data,
      res,
    };
  }

  async get_phases({ organization, project }) {
    var { data, res } = await request({
      url: REPORTING_ENDPOINTS.get_phases,
      method: "POST",
      skipRedirect: true,
      body: {
        organization,
        project,
      },
    });
    return {
      data,
      res,
    };
  }

  async get_categories({ organization, project, phase }) {
    var { data, res } = await request({
      url: REPORTING_ENDPOINTS.get_categories,
      method: "POST",
      skipRedirect: true,
      body: {
        organization,
        project,
        phase,
      },
    });
    return {
      data,
      res,
    };
  }

  async get_levels({ organization, project }) {
    var { data, res } = await request({
      url: REPORTING_ENDPOINTS.get_levels,
      method: "POST",
      skipRedirect: true,
      body: {
        organization,
        project,
      },
    });
    return {
      data,
      res,
    };
  }

  async get_training_data({ project }) {
    var { data, res } = await request({
      url: REPORTING_ENDPOINTS.get_training_data,
      method: "POST",
      skipRedirect: true,
      body: {
        project,
      },
    });
    return {
      data,
      res,
    };
  }
}

export const ReportingService = new Reporting();
