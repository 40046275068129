<template>
  <div>
    <CModal :show.sync="show" alignment="center">
      <template v-slot:header>
        <h3>{{ $t("Rename Level") }}</h3>
        <CButton @click="close">
          <i class="fa fa-times"></i>
        </CButton>
      </template>

      <div class="modal-body">
        <div>
          <label for="level">{{ $t("Level Name") }}:</label>
          <CInput id="level" v-model="levelName" />
        </div>
      </div>
      <template v-slot:footer>
        <CButton @click="close" class="link">{{ $t("Cancel") }}</CButton>
        <CButton
          color="primary"
          class="modal-confirm"
          @click="renameLevel"
          :disabled="!levelName.trim()"
        >
          {{ $t("Rename") }}
        </CButton>
      </template>
    </CModal>
  </div>
</template>
<script>
import { OrganizationsService } from "@/services/organizations";
import vSelect from "vue-select";
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import "vue-select/dist/vue-select.css";
import { mapState } from "vuex";
import { showMessage } from "@/utils/helpers";

export default {
  data() {
    return {
      levelName: "",
    };
  },
  computed: {
    ...mapState({
      currentOrganization: (state) => state.selectedOrg,
      currentProject: (state) => state.selectedProject,
    }),
  },
  components: {
    vSelect,
    Treeselect,
  },
  watch: {
    show: function (val) {
      if (val) {
        this.levelName = this.currentLevelName;
      }
    },
  },
  props: {
    show: Boolean,
    node: Object,
    projectLevel: Boolean,
    currentLevelName: String,
    allLevelsInOrgAndProject: Array,
  },

  methods: {
    close() {
      this.$emit("close");
    },
    async renameLevel() {
      let levelNameExists = false;
      this.allLevelsInOrgAndProject.forEach((level) => {
        if (level.name === this.levelName) {
          levelNameExists = true;
          return;
        }
      });

      if (levelNameExists) {
        showMessage("error", "Level Name Already Exists!");
      } else {
        console.log("is it possible to get here");

        if (!this.projectLevel) {
          const { data, res } = await OrganizationsService.rename_level({
            org_name:
              this.currentOrganization.name ||
              this.currentOrganization.organization,
            level_id: this.node.id,
            new_name: this.levelName,
            prj_name: this.currentProject.name,
          });

          if (data.success) {
            this.$parent.refreshData();
            this.$emit("close");
          } else {
            alert(data.detail);
          }
        } else {
          const { data, res } = await OrganizationsService.rename_project_level(
            {
              org_name:
                this.currentOrganization.name ||
                this.currentOrganization.organization,
              project_name: this.currentProject.name,
              level_id: this.node.id,
              new_name: this.levelName,
            }
          );

          if (data.success) {
            this.$emit("close");
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.modal-confirm {
  border-radius: 0px 0px 0px 25px;
}
</style>
