import { createStore } from "vuex";

const state = {
  sidebarShow: "responsive",
  sidebarMinimize: false,
  isUserLoggedIn: false,
  alert: {
    show: false,
    color: "success",
    duration: 5000,
    message: "",
  },
  selectedEvent: null,
  selectedTraining: null,
  userData: null,
  selectedOrg: null,
  orgObj: null,
  selectedProject: null,
  allOrgs: null,
  includedOrgs: null,
  permissions: null,
  jobsPoints: null,
  selectedJob: null,
  isAdmin: false,
  expandedLevels: null,
  userPoints: null,
  isUserAdmin: false,
};

const mutations = {
  setLoginStatus(state, status) {
    state.isUserLoggedIn = status;
  },
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },
  setAlert(state, config) {
    state.alert = { ...state.alert, ...config, show: true };
  },
  hideAlert() {
    state.alert.show = false;
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  updateSelectedEvent(state, eventData) {
    state.selectedEvent = eventData;
  },
  updateSelectedTraining(state, trainingData) {
    state.selectedTraining = trainingData;
  },
  setUserData(state, userData) {
    state.userData = userData;
  },
  setOrg(state, orgData) {
    state.selectedOrg = orgData;
  },
  setOrgObj(state, orgData) {
    state.orgObj = orgData;
  },
  setProject(state, projectData) {
    state.selectedProject = projectData;
  },
  setAllOrgs(state, orgs) {
    state.allOrgs = orgs;
  },
  setIncludedOrgs(state, orgs) {
    state.includedOrgs = orgs;
  },
  setPermissions(state, permissions) {
    state.permissions = permissions;
  },
  setJobsPoints(state, jobsPoints) {
    state.jobsPoints = jobsPoints;
  },
  setJob(state, jobData) {
    state.selectedJob = jobData;
  },
  setUserPoints(state, userPoints) {
    state.userPoints = userPoints;
  },
  setIsAdmin(state, isAdmin) {
    state.isAdmin = isAdmin;
  },
  setExpandedLevels(state, expandedLevels) {
    state.expandedLevels = expandedLevels;
  },
  setIsUserAdmin(state, isUserAdmin) {
    state.isUserAdmin = isUserAdmin;
  },
};

export default createStore({
  state,
  mutations,
});
