<template>
  <div>
    <div ref="orgSelectContainer">
      <div :class="showOrgSelect ? 'visible' : 'hidden'">
        <div v-for="option in orgsWithProjects" :key="option.organization">
          <div
            :class="
              selectedOrgOption == option.organization &&
              selectedProject == option.projects[0].name
                ? 'option selected'
                : 'option'
            "
            @mouseenter="
              hoverOption(option.organization, option.projects[0].name)
            "
            @mouseleave="hoverOption(null)"
            @click="
              selectOrg(option.organization, option.projects[0].name, $event)
            "
          >
            {{ option.organization }} / {{ option.projects[0].name }}
            <div
              v-if="
                !(
                  option.organization === 'Admin' &&
                  option.projects[0].name === 'Admin'
                )
              "
              class="optionButtons"
              v-show="
                hoveredOption === option.organization + option.projects[0].name
              "
            >
              <div v-c-tooltip="$t('Rename Project')">
                <i
                  @click.stop="
                    toggleEditModal(
                      option.organization,
                      option.projects[0].name
                    )
                  "
                  class="blue fa fa-pencil"
                />
              </div>
              <div v-c-tooltip="$t('Delete Project')">
                <img
                  @click.stop="
                    toggleDeleteModal(
                      option.organization,
                      option.projects[0].name
                    )
                  "
                  class="img"
                  :src="require('@/assets/Images/cross.png')"
                  alt="delete"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="add" @click.stop="toggleCreateModal">
          <div class="text">{{ $t("Add new organization") }}</div>
          <div class="sign">+</div>
        </div>
      </div>
    </div>
    <AddProjectModal
      :show="showAddProjectModal"
      @close="showAddProjectModal = false"
      :selectedOrganization="orgToAddProject"
      @click.stop
    />
  </div>
</template>

<script>
import AddProjectModal from "./modals/AddProjectModal.vue";
export default {
  name: "OrganizationSelect",
  components: { AddProjectModal },
  props: {
    orgOptions: Array,
    showOrgSelect: Boolean,
    selectedOrgOption: String,
    selectedProject: String,
    isLoading: Boolean,
    projects: Array,
    orgsWithProjects: Array,
    chosenProjectToEdit: String,
  },
  data() {
    return {
      hoveredOption: null,
      clickOutsideListener: null,
      showAddProjectModal: false,
      orgToAddProject: null,
    };
  },
  mounted() {
    this.clickOutsideListener = (event) => {
      if (!this.$refs.orgSelectContainer.contains(event.target)) {
        this.close();
      }
    };
    document.addEventListener("click", this.clickOutsideListener);
  },

  beforeUnmount() {
    document.removeEventListener("click", this.clickOutsideListener);
  },

  methods: {
    openAddProjectModal(orgName) {
      this.orgToAddProject = orgName;
      this.showAddProjectModal = true;
    },
    toggleCreateModal() {
      this.$emit("toggleCreateModal");
    },
    toggleEditModal(orgName, projectName) {
      this.$emit("toggleEditModal", orgName, projectName);
    },
    toggleDeleteModal(orgName, projectName) {
      this.$emit("toggleDeleteModal", orgName, projectName);
    },
    hoverOption(orgName, projectName) {
      this.hoveredOption = orgName + projectName;
    },
    async selectOrg(orgName, projectName, event) {
      event.stopPropagation();
      await this.$parent.changeOrgName(orgName);
      await this.$parent.changeProjectName(projectName);
      await this.$parent.refreshEverything();
    },

    close() {
      this.$emit("close");
    },
    getProjectsFromOrg(organization) {
      const orgWithProjects = this.orgsWithProjects.find(
        (org) => org.organization === organization
      );

      return orgWithProjects
        ? orgWithProjects.projects.map((project) => project.name)
        : [];
    },
    getOrgsList() {
      this.$parent.getOrgsList();
    },
    refreshEverything() {
      this.$parent.refreshEverything();
    },
    changeProjectName(projectName) {
      this.$parent.changeProjectName(projectName);
    },
    changeOrgName(orgName) {
      this.$parent.changeOrgName(orgName);
    },
  },
};
</script>

<style scoped lang="scss">
.img {
  width: 12px;
  height: 12px;
  margin-left: 15px;
}
.hidden {
  display: none;
}

.visible {
  display: block;
  background-color: #ffffff;
  width: fit-content;
  min-width: 300px;
  position: absolute;
  box-shadow: 0px 10px 30px 0px #e7f0fa;
  z-index: 999;
  max-height: 500px;
  overflow: auto;
}

.add {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  height: 50px;
  padding: 0px 20px 0px 20px;
  color: #858585;
  width: fit-content;
  transition: 0.1s;

  .text {
    height: 20px;
    line-height: 25px;
  }
  &:hover {
    color: #0071ce;
  }

  .sign {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-weight: 100;
    font-size: 25px;
    margin-left: 5px;
    margin-top: 2px;
  }
}

.selected {
  background-color: #e7f0fa;
}

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px 20px;

  .optionButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    div {
      display: flex;
    }

    .blue {
      color: #0071ce;
    }

    i {
      margin-left: 10px;
    }
  }

  &:hover {
    background-color: #e7f0fa;
  }
}

.inputWrapper {
  border-bottom: 1px solid #9d9d9d;
}

input {
  border: none;
  width: 100%;
  padding: 5px 5px 5px 0px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
}
.header {
  font-size: 18px;
  font-weight: 600;
  cursor: default;
}

footer {
  cursor: default;
}

.inputHeader {
  margin-top: 20px;
  font-size: 12px;
}
.projectOptions:hover {
  background: #087ddd;
  color: #fff;
}
</style>
