import { defineStore } from "pinia";
export const useGlobalStore = defineStore("useGlobalStore", {
  state: () => {
    return {
      sidebarShow: "responsive",
      sidebarMinimize: false,
      isUserLoggedIn: false,
      alert: {
        show: false,
        color: "success",
        duration: 5000,
        message: "",
      },
      userData: null,
      isAdmin: false,
      userPoints: null,
      navbarItems: null,
      gotData: false,
      eventsAreLoading: true,
      selectedUser: {},
      selectedUserRoles: [],
      eventsAreLoading: true,
      isMobile: false,
      updatedProgress: false,
      scopeUsersOptions: [],
      scopeLevelsOptions: [],
      scopeRolesOptions: [],
      challengeToOpen: null,
    };
  },
  getters: {},
  actions: {
    setLoginStatus(status) {
      this.isUserLoggedIn = status;
    },

    toggleSidebarDesktop() {
      const sidebarOpened = [true, "responsive"].includes(this.sidebarShow);
      this.sidebarShow = sidebarOpened ? false : "responsive";
    },

    setAlert(config) {
      this.alert = { ...this.alert, ...config, show: true };
    },
    hideAlert() {
      this.alert.show = false;
    },

    setUserData(userData) {
      this.userData = userData;
    },

    setUserPoints(userPoints) {
      this.userPoints = userPoints;
    },
    setIsAdmin(isAdmin) {
      this.isAdmin = isAdmin;
    },
    setNavbarItems(navbaritems) {
      this.navbarItems = navbaritems;
    },
    setGotData(gotDataState) {
      this.gotData = gotDataState;
    },

    setEventsAreLoading(val) {
      this.eventsAreLoading = val;
    },
    setSelectedUser(val) {
      this.selectedUser = val;
    },
    setSelectedUserRoles(val) {
      this.selectedUserRoles = val;
    },
    setEventsAreLoading(val) {
      this.eventsAreLoading = val;
    },
    setIsMobile(val) {
      this.isMobile = val;
    },
    setUpdatedProgress(val) {
      this.updatedProgress = val;
    },
    setScopeUsersOptions(val) {
      this.scopeUsersOptions = val;
    },
    setScopeLevelsOptions(val) {
      this.scopeLevelsOptions = val;
    },
    setScopeRolesOptions(val) {
      this.scopeRolesOptions = val;
    },
    setChallengeToOpen(val) {
      this.challengeToOpen = val;
    },
  },
});
